import { formatNumber } from "@angular/common";
import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { StadistictStoreEntity } from "src/app/interfaces/Sales-interfaces/Sales-Stadistic";

@Injectable({
  providedIn: "root",
})
export class CalculationsService {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
date1:any;
date2:any;

  sum(key: keyof any, array: any[]) {
    return array.reduce((a, b) => a + (Number(b[key]) || 0), 0);
  }

  average(
    keyA: keyof StadistictStoreEntity,
    keyB: keyof StadistictStoreEntity,
    dataTable: any[]
  ) {
    const element={
      valueA:dataTable.reduce((a: any, b: any) => a + (Number(b[keyA]) || 0),0),
      valueB: dataTable.reduce((a: any, b: any) => a + (Number(b[keyB]) || 0),0)
    }
   
    const total = element.valueA / element.valueB;
    const valueUpt = !!total ? total : 0.0;

    return valueUpt;
  }

  Date(): any {
    let strDate = new Date();
    return {
      startDate: `${strDate.getFullYear()}-01-01`,
      endDate: `${strDate.getFullYear()}-${strDate.getMonth() + 1}-${strDate.getDate()}`,
    };
  }

  formatDashBoard(number: number): any {
    return formatNumber(number, this.locale, "1.2-2");
  }

}
