import { HttpClient } from "@angular/common/http";

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import {
  CalculationsService,
  UserService,
  FileService,
  DataTableService,
  DbService,
  OrderService,
  ElementService,
} from "src/app/services/export-services";
import {
  elementsExcel,
  getElements,
  tableElements,
  Order,
  swalElements,
} from "src/app/interfaces/export-interfaces";
import { environment } from "src/environments/environment.prod";


@Component({
  selector: "app-orders-list",
  templateUrl: "./orders-list.component.html",
  styleUrls: ["./orders-list.component.scss"],
})
export class OrdersListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public _http: HttpClient,
    public _userService: UserService,
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: OrderService,
    private renderer: Renderer2,
    private sharedService: FileService,
    private calculate: CalculationsService,
    private elementView: ElementService,
    
  ) {}

  subTable: Subscription;
  show$: Observable<boolean> = this.dataTable.showSpinner;
  getOrder: getElements = {
    finalUrl: "/getListOrders/",
    token: true,
    data1: "",
    data2: "",
  };

  componentsTable: tableElements = {
    url: this.db_service.getData(this.getOrder),
    id: "#dataTable",
    html: this.service.orderHtml,
    actions: this.service.orderButtons,
  };

  excel: elementsExcel = {
    url: this.db_service.getData(this.getOrder),
    name: "pedidos",
  };

  ngOnInit(): void {
    this.createTable();
  }

  ngAfterViewInit(): void {
    this.renderer.listen("document", "click", (event) => {
      this.service.getIdOrder(event);

      this.service.navigateDetailOrder(event);
    });
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }

  public sum(key: keyof Order): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }

  ordersUp(event: Event) {
    let token = this._userService.getToken();

    const target = event.target as HTMLInputElement;
    const totalFile = target.files?.length == null ? 0 : target.files?.length;
    let mens = "";
    for (let nFile = 0; nFile < totalFile || 0; nFile++) {
      let file: File = (target.files as FileList)[nFile];
      if (file) {
        let fileName = file.name;
        let extFile = fileName.slice(-3);
        if (extFile === "csv") {
          let formData = new FormData();
          formData.append("file_csv", file);

          this._http.post<any>(environment.baseUrlApi + "/pedido/alta/" + token + "/csv", formData).subscribe({
            next: data => {
              this.elementView.alertSwal(
                {
                  icon: data.error == true ? "error" : "success",
                  title: data.message == "ok" ? "Pedido procesado Correctamente" : data.message,
                  confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar'
                }
              ).then(() => {
                  if(data.error != true) { location.reload(); }
                }
              );
            },
            error: error => {
              this.elementView.alertSwal({
                icon: "error",
                title: error.error.message,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
              });
            }
        })
        } else {
          this.elementView.alertSwal({
            icon: "error",
            title: 'Solo se admiten archivos CSV. Has subido un archivo ' + extFile,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
          });
        }
      }
    }   
  }

  ordersCup(event: Event) {   
    let token = this._userService.getToken();
    const target = event.target as HTMLInputElement;
    const totalFile = target.files?.length == null ? 0 : target.files?.length;

    for (let nFile = 0; nFile < totalFile || 0; nFile++) {
      let file: File = (target.files as FileList)[nFile];
      if (file) {
         let fileName = file.name;

        let extFile = fileName.slice(-3);

        if (extFile === "EDI") {
          let formData = new FormData();

          formData.append("fileEdi", file);

          let upload$:Observable<any>
          upload$ = this._http.post(environment.baseUrlApi + "/postUploadFileEdi/" + token, formData);
          upload$.subscribe(
            (response) => { 
              this.elementView.alertSwal({
                icon: "success",
                title: 'Archivo subido correctamente.',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
              });
            },
            (error) => {
              this.elementView.alertSwal({
                icon: "error",
                title: error.error.message,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
              });
            }
          );
        } else {
          this.elementView.alertSwal({
            icon: "error",
            title: 'Solo se admiten archivos EDI. Has subido un archivo ' + extFile,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
          });
        }
      }
      location.reload();
    }
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, 5);

      this.dataTable.selectDatatable(this.componentsTable, response);

    });
  }

  resetTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.resetTable(this.componentsTable.html, response);
    });
  }
  reloadTable() {
    this.dataTable.dataTable.clear();
    this.dataTable.showSpinner.next(true);
    this.componentsTable.url = this.db_service.getData(this.getOrder);

    this.resetTable();
  }
}
