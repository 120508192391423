import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { CalculationsService, FileService, DataTableService, DbService, SalesService } from "src/app/services/export-services";
import { elementsExcel, getElements, tableElements,SaleItems } from "src/app/interfaces/export-interfaces";
@Component({
  selector: "app-sales-articles-general",
  templateUrl: "./sales-articles-general.component.html",
  styleUrls: ["./sales-articles-general.component.scss"],
})
export class SalesArticlesGeneralComponent implements OnInit, OnDestroy {

  constructor(
    private db_service: DbService,
    private sharedService: FileService,
    private dataTable: DataTableService,
    private service: SalesService,
    private calculate: CalculationsService
  ) {}


  subTable:Subscription
  show$:Observable<boolean>= this.dataTable.showSpinner;
  private startDate: string = this.calculate.Date().startDate;
  private endDate: string = this.calculate.Date().endDate;

  getStadistic:getElements = {
    finalUrl: "/getTopSaleItem/",
    token: true,
    data1: "/" + this.startDate + "/",
    data2: this.endDate,
  };

  componentsTable:tableElements = {
    url: this.db_service.getData(this.getStadistic),
    id: "#dataTable",
    html: this.service.generalSalesHtml,
  };

  excel:elementsExcel = {
    url: this.db_service.getData(this.getStadistic),
    name: "articulos_general",
  };

  ngOnInit(): void {
   this.createTable();
    this.dataTable.showSpinner.next(true);
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }
  
  public sum(key: keyof SaleItems): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }

  resetTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.resetTable(this.componentsTable.html, response);
    });
  }

  searchDate() {
    this.dataTable.dataTable.clear()
    this.dataTable.showSpinner.next(true)
    const data:{[key:string]:string} = {
      range1:
        "/" +
        this.calculate.date1.year +
        "-" +
        this.calculate.date1.month +
        "-" +
        this.calculate.date1.day +
        "/",
      range2:
        this.calculate.date2.year +
        "-" +
        this.calculate.date2.month +
        "-" +
        this.calculate.date2.day,
    };
    this.getStadistic.data1 = data.range1;
    this.getStadistic.data2 = data.range2;
    this.componentsTable.url = this.db_service.getData(this.getStadistic);

    this.resetTable();
  }


  reloadTable() {
    this.dataTable.dataTable.clear()
    this.dataTable.showSpinner.next(true)
    this.getStadistic.data1 = "/" + this.startDate + "/";
    this.getStadistic.data2 = this.endDate;
    this.componentsTable.url = this.db_service.getData(this.getStadistic);

    this.resetTable();
  }
  
}
