import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormService } from "src/app/services/shared/forms/form.service";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  constructor(private reactiveForm: FormService) {}
  addForm!: FormGroup;
  ngOnInit(): void {
    this.addForm = this.reactiveForm.employee();
  }

  isDisabled(): boolean {
    return this.addForm.valid ? false : true;
  }
}
