import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ListUsersComponent } from "./list-users/list-users.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { UserAddRouting, UserEditRouting, UserListRouting } from "./user-routes.module";
import { smallComponentsModule } from "src/app/views/smalls-components/smalls-components.module";

@NgModule({
  declarations: [
    ListUsersComponent,
    AddUserComponent,
    EditUserComponent,

  ],
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule,smallComponentsModule],
  exports: [
    ListUsersComponent,
    AddUserComponent,
    EditUserComponent,
  ],
})
export class UserModule {}


@NgModule({
  imports: [UserListRouting],
})
export class UserListRoute {}

@NgModule({
  imports: [UserAddRouting],
})
export class UserAddRoute {}

@NgModule({
  imports: [UserEditRouting],
})
export class UserEditRoute {}