import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [


  {
    icon:"grid",
    label: "Dashboard",
    link: "/dashboard",
  },
  {
    icon:"bar-chart-2",
    label: "Ventas",
    link: "/sales",
  },
  {
    icon:"box",
    label: "Catálogo",
    link: "/catalog",
  },
  {
    icon:"shopping-cart",
    label: "Pedidos",
    link: "/orders",
  },
  {
    icon:"users",
    label: "Clientes",
    link: "/clients",
  },
  {
    icon:"briefcase",
    label: "Empleados",
    link: "/employee",
  },
  /*{
    icon:"user",
    label: "Usuarios",
    link: "/user",
  },*/
];
