import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { CalculationsService, FileService, DataTableService, DbService, SalesService,UserService} from "src/app/services/export-services";
import { elementsExcel, getElements, tableElements,SaleStore } from "src/app/interfaces/export-interfaces";
@Component({
  selector: "app-sales-month",
  templateUrl: "./sales-month.component.html",
  styleUrls: ["./sales-month.component.scss"],
})
export class SalesMonthComponent implements OnInit, OnDestroy {
  constructor(
    public _http: HttpClient,
    public _userService: UserService,
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: SalesService,
    private sharedService: FileService,
    private calculate: CalculationsService
  ) {}


  subTable: Subscription;


  option: number = 0;

  gets:{[key:string]:getElements} = {
    money: {
      finalUrl: "/getSalesStorexMonth/",
      token: true,
      data1: "/" + 0,
      data2: "",
    },
    amount: {
      finalUrl: "/getSalesStorexMonth/",
      token: true,
      data1: "/" + 1,
      data2: "",
    },
  };
  show$:Observable<boolean>= this.dataTable.showSpinner;
  componentsTable:tableElements = {
    url: this.db_service.getData(this.gets.money),
    id: "#dataTable",
    html: this.service.monthSalesHtml,
  };

  componentsTable2:tableElements = {
    url: this.db_service.getData(this.gets.amount),
    id: "#dataTable2",
    html: this.service.monthSalesHtml2,
  };

  excel:elementsExcel = {
    url: this.db_service.getData(this.gets.money),
    name: "ventas_mes_euros",
  };

  excel2:elementsExcel = {
    url: this.db_service.getData(this.gets.amount),
    name: "ventas_mes_cantidad",
  };

  ngOnInit(): void {
    this.money();
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    // this.sub.unsubscribe();
    this.subTable.unsubscribe();
  }

  money() {
    this.option = 0;
    this.createTable();
  }

  amount() {
    this.option = 1;
    this.createTable2();
  }

  public sum(key: keyof SaleStore): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  downloadExcel() {
    this.option == 0
      ? this.sharedService.downloadExcel(this.excel)
      : this.sharedService.downloadExcel(this.excel2);
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }

  createTable2() {
    this.subTable = this.componentsTable2.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable2.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable2, response);
    });
  }

  resetTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.resetTable(this.componentsTable.html, response);
    });
  }

  resetTable2() {
    this.subTable = this.componentsTable2.url.subscribe((response) => {
      this.dataTable.resetTable(this.componentsTable2.html, response);
    });
  }
  reloadTable() {
    this.dataTable.dataTable.clear();
    this.dataTable.showSpinner.next(true);

    this.resetTable2();
  }

  reloadTable2() {
    this.dataTable.dataTable.clear();
    this.dataTable.showSpinner.next(true);

    this.resetTable();
  }
}
