import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EmployeeService } from 'src/app/services/component-service/employee/employee.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { FormService } from 'src/app/services/shared/forms/form.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  constructor(private service:EmployeeService,private db_service:DbService,private reactiveForm:FormService) { }
  idClient: number = this.service.idEmployee;
  editForm!: FormGroup;
  ngOnInit(): void {
    this.editForm = this.reactiveForm.employee();
  }

  isDisabled(): boolean {
    return this.editForm.valid ? false : true;
  }
}
