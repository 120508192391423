import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { cardsElements, getElements } from "src/app/interfaces/export-interfaces";
import { DbService } from "src/app/services/export-services";

@Component({
  selector: "app-dashboard-cards",
  templateUrl: "./dashboard-cards.component.html",
  styleUrls: ["./dashboard-cards.component.scss"],
})
export class DashboardCardsComponent implements OnInit, OnDestroy {
  constructor(private db_service: DbService) { }

  gets: { [key: string]: getElements } = {
    annual: {
      finalUrl: "/getTotalSaleAnnual/",
      token: true,
      data1: "",
      data2: "",
    },
    month: {
      finalUrl: "/getTotalSaleMonth/",
      token: true,
      data1: "",
      data2: "",
    },
    upt: {
      finalUrl: "/getUptMonth/",
      token: true,
      data1: "",
      data2: "",
    },
    ticket: {
      finalUrl: "/getAvarageTicketMonth/",
      token: true,
      data1: "",
      data2: "",
    },

    top: {
      finalUrl: "/getTopSales/",
      token: true,
      data1: "",
      data2: "",
    },
  };
  data: { [key: string]: cardsElements } = {
    top: {
      get$: this.db_service.getData(this.gets.top),
      data: "",
      sub: new Subscription(),
    },
    annual: {
      get$: this.db_service.getData(this.gets.annual),
      data: "",
      sub: new Subscription(),
    },
    month: {
      get$: this.db_service.getData(this.gets.month),
      data: "",
      sub: new Subscription(),
    },
    upt: {
      get$: this.db_service.getData(this.gets.upt),
      data: "",
      sub: new Subscription(),
    },
    ticket: {
      get$: this.db_service.getData(this.gets.ticket),
      data: "",
      sub: new Subscription(),
    },
  };

  ngOnInit(): void {
    this.data.top.sub = this.data.top.get$.subscribe(
      (res: string) => (this.data.top.data = res)
    );

    this.data.annual.sub = this.data.annual.get$.subscribe(
      (res: string) => (this.data.annual.data = res)
    );

    this.data.month.sub = this.data.month.get$.subscribe(
      (res: string) => (this.data.month.data = res)
    );

    this.data.upt.sub = this.data.upt.get$.subscribe(
      (res: string) => (this.data.upt.data = res)
    );

    this.data.ticket.sub = this.data.ticket.get$.subscribe(
      (res: string) => (this.data.ticket.data = res)
    );
  }

  ngOnDestroy(): void {
    this.data.top.sub.unsubscribe();

    this.data.annual.sub.unsubscribe();

    this.data.month.sub.unsubscribe();

    this.data.upt.sub.unsubscribe();

    this.data.ticket.sub.unsubscribe();
  }
}
