import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { BackArrowComponent } from "src/app/views/smalls-components/back-arrow/back-arrow.component";
import { DownloadExcelComponent } from "./download-excel/download-excel.component";
import { SpinnersLoadComponent } from "./spinners-load/spinners-load.component";
import { DatePickerComponent } from "./date-picker/date-picker.component";


@NgModule({
  declarations: [
    DownloadExcelComponent,
    BackArrowComponent,
    SpinnersLoadComponent,
    DatePickerComponent
  ],
  imports: [CommonModule,NgbModule],
  exports: [ BackArrowComponent,DownloadExcelComponent,SpinnersLoadComponent, DatePickerComponent],
})
export class smallComponentsModule {}
