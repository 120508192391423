import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { EmployeeService } from "src/app/services/component-service/employee/employee.service";
import { DbService } from "src/app/services/db-service/db.service";
import { ElementService } from "src/app/services/shared/visible-elements/elements.service";

import { FormBuilder, FormGroup } from "@angular/forms";


@Component({
  selector: 'app-control-history-employee-external',
  templateUrl: './control-history-employee-external.component.html',
  styleUrls: ['./control-history-employee-external.component.scss']
})
export class ControlHistoryEmployeeExternalComponent implements OnInit, AfterViewInit {
  constructor(
    private db_service: DbService,
    private service: EmployeeService,
    private renderer: Renderer2,
    private elementView: ElementService,
    private builder: FormBuilder,
  ) {}

  addForm!: FormGroup;
  show: boolean;
  empleado: any;
  showButton: boolean = true;
  swalOptions = {
    icon: "error",
    title: "Código no existente",
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
  };
  checkEmploye: boolean = false;
  date: Date;
  swalOptions2 = {
    title: "",
    text: "",
    showCancelButton: true,
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
    cancelButtonText: '<i class="fa fa-thumbs-up"></i> Cancelar',
  };
  latitude: number = 0;
  longitude: number = 0;
  ngOnInit(): void {
    this.addForm = this.builder.group({
      cod: [""],
    });
    this.getLocation();
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {}



  buttom() {
    let get = {
      finalUrl: "/EmployeeHistory/",
      token: true,
      data1: this.empleado.id + "/",
      data2: "",
    };

    this.db_service.getData2(get).subscribe((res) => {
      console.log(res);
      if (res == null) {
        this.showButton = false;
      } else {
        Number(res[0].TIPO) == 1
          ? (this.showButton = true)
          : (this.showButton = false);
      }
    });
  }

  checkEmployee() {
    let get = {
      finalUrl: "/getListEmployee/",
      token: true,
      data1: "",
      data2: "",
    };
    this.db_service.getData(get).subscribe((res) => {
      res.forEach((element) => {
        if (this.addForm.controls.cod.value == element.code) {
          this.checkEmploye = true;
          this.empleado = element;
        }
      });
      if( this.checkEmploye ==false){
        this.elementView.alertSwal(this.swalOptions)
      }
      this.buttom();
    });
  }

  changeButton() {
    this.date = new Date();
    this.swalOptions2.text = this.date.toLocaleString();
    this.swalOptions2.title = this.showButton == false ? "Entrada" : "Salida";
    this.elementView.alertSwal(this.swalOptions2).then((res) => {
      if (res.isConfirmed == true) {
        if (this.showButton == true) {
          this.showButton = false;
          this.entranceControl();
        } else {
          this.showButton = true;
          this.exitControl();
        }
      }
    });
  }

  showButton2() {
    this.checkEmploye = false;
  }

  arrayEjemplo: any[] = [];

  entranceControl() {
    this.arrayEjemplo.push({
      IDEMPLEADO: this.empleado.id,
      FECHA: this.date.toLocaleString(),
      TIPO: 1,
      LAT: this.latitude,
      LON: this.longitude,
      NOMBRE: this.empleado.name,
    });
    console.log(this.arrayEjemplo);
  }
  exitControl() {
    this.arrayEjemplo.push({
      IDEMPLEADO: this.empleado.id,
      FECHA: this.date.toLocaleString(),
      TIPO: 0,
      LAT: this.latitude,
      LON: this.longitude,
      NOMBRE: this.empleado.name,
    });
    console.log(this.arrayEjemplo);
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  getLocation() {
    this.getPosition().then((pos) => {
      this.latitude = pos.lat;
      this.longitude = pos.lng;
    });
  }

  back(){
    this.checkEmploye=false;
  }
}
