import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'back-arrow',
  templateUrl: './back-arrow.component.html',
  styleUrls: ['./back-arrow.component.scss']
})
export class BackArrowComponent implements OnInit {

  constructor(private _location:Location) { }

  ngOnInit(): void {
  }

  backClicked() {
    this._location.back();
  }
}
