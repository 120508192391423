import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { elementsExcel, getElements, Order, tableElements } from "src/app/interfaces/export-interfaces";
import { CalculationsService,DataTableService,DbService,FileService,UserService,OrderService } from "src/app/services/export-services";


@Component({
  selector: "app-orders-detail",
  templateUrl: "./orders-detail.component.html",
  styleUrls: ["./orders-detail.component.scss"],
})
export class OrdersDetailComponent implements OnInit, OnDestroy {
  constructor(
    public _http: HttpClient,
    public _userService: UserService,
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: OrderService,
    private sharedService: FileService,
    private calculate: CalculationsService
  ) {}

  subTable: Subscription;
  show$:Observable<boolean>= this.dataTable.showSpinner;

  getOrder:getElements = {
    finalUrl: "/getDetailsOrder/",
    token: true,
    data1: "/" + this.service.idOrder,
    data2: "",
  };
  componentsTable:tableElements = {
    url: this.db_service.getData(this.getOrder),
    id: "#dataTable",
    html: this.service.orderDetailHtml,
  };

  excel:elementsExcel = {
    url: this.db_service.getData(this.getOrder),
    name: "pedidos_detalle",
  };

  ngOnInit(): void {
    this.createTable();
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }

  sum(key: keyof Order): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }
}
