import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { CalculationsService, FileService, DataTableService, DbService, SalesService,UserService} from "src/app/services/export-services";
import { elementsExcel, getElements, tableElements,StadistictStoreEntity } from "src/app/interfaces/export-interfaces";
@Component({
  selector: "app-sales-employees",
  templateUrl: "./sales-employees.component.html",
  styleUrls: ["./sales-employees.component.scss"],
})
export class SalesEmployeesComponent implements OnInit, OnDestroy {
  constructor(
    public _http: HttpClient,
    public _userService: UserService,
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: SalesService,
    private sharedService: FileService,
    private calculate: CalculationsService
  ) {}

  sub: Subscription;
  show: boolean;
  subTable: Subscription;
  show$:Observable<boolean>= this.dataTable.showSpinner;
  startDate: string = this.calculate.Date().startDate;
  endDate: string = this.calculate.Date().endDate;

  getSales:getElements = {
    finalUrl: "/getStatisticsEmployee/",
    token: true,
    data1: "/" + this.startDate + "/",
    data2: this.endDate,
  };

  componentsTable:tableElements = {
    url: this.db_service.getData(this.getSales),
    id: "#dataTable",
    html: this.service.employeeSalesHtml,
  };

  excel:elementsExcel = {
    url: this.db_service.getData(this.getSales),
    name: "ventas_empleados",
  };

  ngOnInit(): void {
    this.sub = this.dataTable.showSpinner.subscribe((res) => {
      this.show = res;
    });
    this.createTable();
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.sub.unsubscribe();
    this.subTable.unsubscribe();
  }

  public sum(key: keyof StadistictStoreEntity): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  average(
    keyA: keyof StadistictStoreEntity,
    keyB: keyof StadistictStoreEntity
  ): number {
    return this.calculate.average(keyA, keyB, this.dataTable.arrayTable);
  }

  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }

  resetTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.resetTable(this.componentsTable.html, response);
    });
  }

  searchDate() {
    this.dataTable.dataTable.clear()
    this.dataTable.showSpinner.next(true)
    const data = {
      range1:
        "/" +
        this.calculate.date1.year +
        "-" +
        this.calculate.date1.month +
        "-" +
        this.calculate.date1.day +
        "/",
      range2:
        this.calculate.date2.year +
        "-" +
        this.calculate.date2.month +
        "-" +
        this.calculate.date2.day,
    };
    this.getSales.data1 = data.range1;
    this.getSales.data2 = data.range2;
    this.componentsTable.url = this.db_service.getData(this.getSales);

    this.resetTable();
  }

  reloadTable() {
    this.dataTable.dataTable.clear()
    this.dataTable.showSpinner.next(true)
    this.getSales.data1 = "/" + this.startDate + "/";
    this.getSales.data2 = this.endDate;
    this.componentsTable.url = this.db_service.getData(this.getSales);

    this.resetTable();
  }

}
