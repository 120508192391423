import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ClientsAddComponent } from "./clients-add/clients-add.component";
import { ClientsEditComponent } from "./clients-edit/clients-edit.component";
import { ClientsDetailsComponent } from "./clients-details/clients-details.component";
import { ClientsListComponent } from "./clients-list/clients-list.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { smallComponentsModule } from "src/app/views/smalls-components/smalls-components.module";
import { ClientAddRouting, ClientDetailRouting, ClientEditRouting, ClientListRouting } from "./client-routes.module";

@NgModule({
  declarations: [
    ClientsAddComponent,
    ClientsEditComponent,
    ClientsDetailsComponent,
    ClientsListComponent,
  ],
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule,smallComponentsModule],
  exports: [
    ClientsAddComponent,
    ClientsEditComponent,
    ClientsDetailsComponent,
    ClientsListComponent,
  ],
})
export class ClientModule {}


@NgModule({
  imports: [ClientListRouting],
})
export class ClientListRoute {}

@NgModule({
  imports: [ClientAddRouting],
})
export class ClientAddRoute {}

@NgModule({
  imports: [ClientEditRouting],
})
export class ClientEditRoute {}

@NgModule({
  imports: [ClientDetailRouting],
})
export class ClientDetailRoute {}
