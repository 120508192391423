import { Component} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Location } from "@angular/common";
import { EmployeeService,FormService,ElementService } from "src/app/services/export-services";
import { swalElements } from "src/app/interfaces/export-interfaces";

@Component({
  selector: "app-add-employee",
  templateUrl: "./add-employee.component.html",
  styleUrls: ["./add-employee.component.scss"],
})
export class AddEmployeeComponent{
  constructor(
    private reactiveForm: FormService,
    private service: EmployeeService,
    private elementView:ElementService,
    private _location:Location
  ) {}
  addForm: FormGroup= this.reactiveForm.employee();
  swalOptions:swalElements = {
    icon: "success",
    title: "Empleado añadido Correctamente",
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
  };

  addEmployee() {
    this.service.addEmployee(this.addForm);
    this.alert();
  }

  private alert() {
    this.elementView.alertSwal(this.swalOptions).then(() => this._location.back());
  }


}
