import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as XLSX from "xlsx";
import { UserService } from "../../auth-service/user.service";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { genericElements } from "src/app/interfaces/Elements-interfaces/generic_interface";


@Injectable({
  providedIn: "root",
})


export class FileService {
  public fileName = "";
  public urlApi: string = "";
  public token: string = "";
  public status: string = "";
  public message: string = "";
  public respuesta: genericElements = {
    error : "",
    message : ""
  };
 
  

  constructor(private _userService: UserService, private http: HttpClient) {
    this.urlApi = environment.baseUrlApi;
    this.token = _userService.getToken();
  }

  downloadExcel(option: any) {
    option.url.subscribe(
      (data: any) => {
        const element = {
          workBook: XLSX.utils.book_new(),
          workSheet: XLSX.utils.json_to_sheet(data)
        }
        XLSX.utils.book_append_sheet(element.workBook, element.workSheet, "data");
        XLSX.writeFile(element.workBook, option.name + ".xlsx");
      }
    );
  }
}
