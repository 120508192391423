import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CatalogStock } from "src/app/interfaces/Catalog-interfaces/catalog-stock";
import { CatalogStoreStock } from "src/app/interfaces/Catalog-interfaces/catalog-store-stock";
const result = new Intl.NumberFormat("es", { maximumSignificantDigits: 10 });
@Injectable({
  providedIn: "root",
})
export class CatalogService {
  constructor(private router: Router) {}
  idStore: number = -1;

//datatables content

  catalogCenterHtml(element: CatalogStoreStock): any[] {
    return [
      element.store + switchType(element.TIPO),
      element.itemsShipped,
      element.itemSold,
      result.format(element.stock),
      "",
    ];
  }

  catalogWarehouseHtml(element: CatalogStock): any[] {
    return [
      element.store,
      element.codFamily,
      element.season,
      element.seasonYear,
      element.reference,
      element.description,
      element.ean,
      element.family,
      element.color,
      element.size,
      element.price,
      element.quantity,
    ];
  }

  orderButtons(response: any): any {
    return [
      {
        select: 4,
        render: function (data, cell, row) {
          return (
            data +
            '<a id="boton-editar" title="detalle Tienda"><i class="mdi mdi-file-find"  style="font-size:200%" detail-store id-store=' +
            response[row.dataIndex].IDCLIENTE +
            "></i></a> "
          );
        },
      },
    ];
  }

//buttons events datatable

  getIdStore(event: any) {
    event.target.hasAttribute("id-store")
      ? (this.idStore = event.target.getAttribute("id-store"))
      : "";
  }

  navigateDetailOrder(event: any) {
    event.target.hasAttribute("detail-store")
      ? this.router.navigate([
          "catalog/warehouse" + event.target.getAttribute("detail-store"),
        ])
      : "";
  }
}

//catalogCenterHtml

function switchType(element: string): string {
  switch (element) {
    case "CLIENTES":
      return '<div class="mx-auto w-25"><span class="badge badge-light ml-5">CLIENTE</span></div>';

    case "ECI":
      return '<div class="mx-auto w-25"><span class="badge badge-success ml-5">ECI</span></div>';

    case "OUTLET":
      return '<div class="mx-auto w-25"><span class="badge badge-info ml-5">OUTLET</span></div>';

    case "PROPIAS":
      return '<div class="mx-auto w-25"><span class="badge badge-warning ml-5">PROPIA</span></div>';

    case "ONLINE":
      return '<div class="mx-auto w-25"><span class="badge badge-primary ml-5">ONLINE</span></div>';

    case "ALMACEN":
      return '<div class="mx-auto w-25"><span class="badge badge-danger ml-5">ALMACÉN</span></div>';
  }
}
