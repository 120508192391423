import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { clientEntity } from "src/app/interfaces/Clients-interface/ClientEntity";
import { DbService } from "../../db-service/db.service";

@Injectable({
  providedIn: "root",
})
export class ClientService {

  constructor(private db_service: DbService, private router: Router) {}
  idClient: number = 0;

  //datatables content

  clientHtml(element: clientEntity): any[] {
    return [
      "<i class='flag-icon flag-icon-" +
        element.CODIGO_PAIS.toLowerCase() +
        "'></i>",
      "<span style='font-size:11px'>" + element.PROVINCIA + "</span>",
      element.CODIGO_CLIENTE +
        "<input type='hidden' idClient'value=" +
        element.IDCLIENTE +
        ">",
      element.CODIGO_TIENDA,
      element.PRIORIDAD,
      element.NIF_CLIENTE,
      "<span style='font-size:11px'>" + element.NOMBRE_CLIENTE + "</span>",
      "<span style='font-size:11px'>" +
        element.DIRECCION_CLIENTE_01 +
        (element.DIRECCION_CLIENTE_02 != ""
          ? "</br>" + element.DIRECCION_CLIENTE_02
          : "") +
        (element.DIRECCION_CLIENTE_03 != ""
          ? "</br>" + element.DIRECCION_CLIENTE_03
          : "") +
        "</span>",
      element.POSTAL,
      "<span style='font-size:11px'>" + element.POBLACION_CLIENTE + "</span>",
      element.TELEFONO,
      switchType(Number(element.IDTIPOCLIENTE)),
      element.ESTADO == 0
        ? '<span class="badge badge-success">Activo</span>'
        : '<span class="badge badge-danger">Inactivo</span>',
      "",
    ];
  }

  clientButtons(response: any): any {
    return [
      {
        select: 13,
        render: function (data, cell, row) {
          return (
            data +
            '<a><i  title="detalle cliente" placement="top" ngbTooltip="Detalle Cliente"  class="mdi mdi-account-search" style="font-size:200%;" detail-cliente id-cliente=' +
            response[row.dataIndex].IDCLIENTE +
            "></i></a> " +
            '<a><i title="editar cliente" class="mdi mdi-account-settings" style="font-size:200%" edit-cliente id-cliente=' +
            response[row.dataIndex].IDCLIENTE +
            "></i></a> "
          );
        },
      },
    ];
  }

  //Forms

  addClient(formValue: FormGroup) {
    this.db_service
      .postData("/Customer/", clientObject(formValue, "add"))
      .subscribe((res) => {});
  }

  editClient(formValue: FormGroup, idClient: number) {
    this.db_service
      .putData("/Customer/", clientObject(formValue), idClient)
      .subscribe((res) => {});
  }

  //buttons events datatable

  getIdClient(event: any) {
    event.target.hasAttribute("id-cliente")
      ? (this.idClient = event.target.getAttribute("id-cliente"))
      : "";
  }

  navigateDetailClient(event: any) {
    event.target.hasAttribute("detail-cliente")
      ? this.router.navigate([
          "/clients/detailClient" + event.target.getAttribute("detail-cliente"),
        ])
      : "";
  }

  navigateEditClient(event: any) {
    event.target.hasAttribute("edit-cliente")
      ? this.router.navigate([
          "/clients/editClient" + event.target.getAttribute("edit-cliente"),
        ])
      : "";
  }
}


//clientHtml

function switchType(element: number) {
  switch (element) {
    case 1:
      return '<div class=" w-25"><span class="badge badge-light">PROPIA</span></div>';

    case 2:
      return '<div class=" w-25"><span class="badge badge-warning">DEPÓSITO</span></div>';

    case 3:
      return '<div class="w-25"><span class="badge badge-info ">FIRMES</span></div>';

    case 4:
      return '<div class=" w-25"><span class="badge badge-success">ECI</span></div>';

    case 5:
      return '<div class=" w-25"><span class="badge badge-primary">ONLINE</span></div>';

    case 6:
      return '<div class=" w-25"><span class="badge badge-danger">ALMACÉN</span></div>';
  }
}

//addClient | editClient

function clientObject(formValue: any, option?: string): clientEntity {
  return {
    CODIGO_CLIENTE: formValue.value.codClient,
    CODIGO_TIENDA: formValue.value.codStore,
    DIRECCION_CLIENTE_01: formValue.value.address_1,
    DIRECCION_CLIENTE_02: formValue.value.address_2,
    DIRECCION_CLIENTE_03: formValue.value.address_3,
    ESTADO: option == "add" ? 0 : formValue.value.state,
    IDPAIS: formValue.value.country,
    IDPROVINCIA: formValue.value.province,
    NIF_CLIENTE: formValue.value.nif,
    NOMBRE_CLIENTE: formValue.value.name,
    POBLACION_CLIENTE: formValue.value.poblation,
    POSTAL: formValue.value.cp,
    PRIORIDAD: formValue.value.priority,
    TELEFONO: formValue.value.phone,
    IDTIPOCLIENTE: formValue.value.type,
  };
}
