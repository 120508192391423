import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { clientEntity } from "src/app/interfaces/Clients-interface/ClientEntity";

@Injectable({
  providedIn: "root",
})
export class FormService {
  constructor(private readonly builder: FormBuilder) {}
idCountry:string='';
  client(): any {
    return this.builder.group({
      name: ["", [Validators.required]],
      priority: ["", [Validators.required]],
      codClient: ["", [Validators.required]],
      codStore: ["", [Validators.required]],
      nif: [""],
      cp: [""],
      phone: [""],
      poblation: [""],
      country: ["", [Validators.required]],
      province: [0],
      address_1: [""],
      address_2: [""],
      address_3: [""],
      state: [""],
      type: ["", [Validators.required]],
    });
  }

  valueEditForm(form: FormGroup, client: clientEntity) {
    String(client.ESTADO) == "" ? (client.ESTADO = 0) : "";
    form.controls["name"].setValue(client.NOMBRE_CLIENTE.trim());
    form.controls["priority"].setValue(client.PRIORIDAD.trim());
    form.controls["codClient"].setValue(client.CODIGO_CLIENTE.trim());
    form.controls["codStore"].setValue(client.CODIGO_TIENDA.trim());
    form.controls["nif"].setValue(client.NIF_CLIENTE.trim());
    form.controls["cp"].setValue(client.POSTAL.trim());
    form.controls["phone"].setValue(client.TELEFONO.trim());
    form.controls["poblation"].setValue(client.POBLACION_CLIENTE.trim());
    form.controls["country"].setValue(client.IDPAIS.trim());
    form.controls["province"].setValue(client.IDPROVINCIA.trim());
    form.controls["address_1"].setValue(client.DIRECCION_CLIENTE_01);
    form.controls["address_2"].setValue(client.DIRECCION_CLIENTE_02);
    form.controls["address_3"].setValue(client.DIRECCION_CLIENTE_03);
    form.controls["state"].setValue(client.ESTADO);
    form.controls["type"].setValue(client.IDTIPOCLIENTE);

  }

  valueEditForm2(form: FormGroup, res: any){
    res.subscribe((client=>{
      this.idCountry=client[0].IDPAIS.trim();
      String(client[0].ESTADO) == "" ? (client[0].ESTADO = 0) : "";
      form.controls["name"].setValue(client[0].NOMBRE_CLIENTE.trim());
      form.controls["priority"].setValue(client[0].PRIORIDAD.trim());
      form.controls["codClient"].setValue(client[0].CODIGO_CLIENTE.trim());
      form.controls["codStore"].setValue(client[0].CODIGO_TIENDA.trim());
      form.controls["nif"].setValue(client[0].NIF_CLIENTE.trim());
      form.controls["cp"].setValue(client[0].POSTAL.trim());
      form.controls["phone"].setValue(client[0].TELEFONO.trim());
      form.controls["poblation"].setValue(client[0].POBLACION_CLIENTE.trim());
      form.controls["country"].setValue(client[0].IDPAIS.trim());
      form.controls["province"].setValue(client[0].IDPROVINCIA.trim());
      form.controls["address_1"].setValue(client[0].DIRECCION_CLIENTE_01);
      form.controls["address_2"].setValue(client[0].DIRECCION_CLIENTE_02);
      form.controls["address_3"].setValue(client[0].DIRECCION_CLIENTE_03);
      form.controls["state"].setValue(client[0].ESTADO);
      form.controls["type"].setValue(client[0].IDTIPOCLIENTE);
    }))
  

  }

  employee(): any {
    return this.builder.group({
      id: [""],
      name: ["",[Validators.required]],
      cod: ["", [Validators.required]],
      state: [""],
    });
  }

  valueEditEmployeeForm(form: FormGroup, employee: any) {
    String(employee.state) == "" ? (employee.state = 0) : "";
    form.controls["name"].setValue(employee.name.trim());
    form.controls["id"].setValue(employee.id.trim());
    form.controls["cod"].setValue(employee.code);
    form.controls["state"].setValue(employee.state);
  }
}
