import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { SaleItemsByStore } from "src/app/interfaces/Sales-interfaces/SaleItemsByStore";
import { elementsExcel, getElements, tableElements } from "src/app/interfaces/export-interfaces";
import { CatalogService, DbService, CalculationsService, DataTableService, FileService } from "src/app/services/export-services";


@Component({
  selector: "app-catalog-stock-warehouse",
  templateUrl: "./catalog-stock-warehouse.component.html",
  styleUrls: ["./catalog-stock-warehouse.component.scss"],
})
export class CatalogStockWarehouseComponent implements OnInit, OnDestroy {
  constructor(
    private dataTable: DataTableService,
    private service: CatalogService,
    private sharedService: FileService,
    private db_service: DbService,
    private calculate: CalculationsService
  ) {}


  subTable: Subscription;
  show$:Observable<boolean>= this.dataTable.showSpinner;

  get:getElements = {
    finalUrl: "/getStockWarehouse2/",
    token: true,
    data1: "",
    data2: "/" + this.service.idStore,
  };

  componentsTable:tableElements = {
    url: this.db_service.getData(this.get),
    id: "#dataTable",
    html: this.service.catalogWarehouseHtml,
  };

  excel:elementsExcel = {
    url: this.db_service.getData(this.get),
    name: "catalogo_warehouse",
  };

  ngOnInit(): void {
    this.service.idStore == -1 ? this.dataTable.showSpinner.next(false) : "";
    this.createTable();
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }


//Passive functions
  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }

  sum(key: keyof SaleItemsByStore): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }
  

//Active functions
  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }
}
