import { NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";


import { SalesEmployeesComponent } from "./sales-employees/sales-employees.component";
import { SalesComponent } from "./sales/sales.component";
import { SalesDayComponent } from "./sales-day/sales-day.component";
import { SalesDayWeekComponent } from "./sales-day-week/sales-day-week.component";
import { SalesHourComponent } from "./sales-hour/sales-hour.component";
import { SalesMonthComponent } from "./sales-month/sales-month.component";
import { SalesNavbarComponent } from "./sales-navbar/sales-navbar.component";
import { SalesArticlesCenterComponent } from "./sales-articles/sales-articles-center/sales-articles-center.component";
import { SalesArticlesGeneralComponent } from "./sales-articles/sales-articles-general/sales-articles-general.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SalesArticleCenterRouting, SalesArticleGeneralRouting, SalesDayRouting, SalesDayWeekRouting, SalesEmployeeRouting, SalesHourRouting, SalesMonthRouting, SalesRouting } from "./sales-routes.module";


import { smallComponentsModule } from "src/app/views/smalls-components/smalls-components.module";

@NgModule({
  declarations: [
    SalesEmployeesComponent,
    SalesComponent,
    SalesDayComponent,
    SalesDayWeekComponent,
    SalesHourComponent,
    SalesMonthComponent,
    SalesNavbarComponent,
    SalesArticlesCenterComponent,
    SalesArticlesGeneralComponent,


  ],
  imports: [CommonModule,NgbModule,smallComponentsModule],
  exports: [
    SalesEmployeesComponent,
    SalesComponent,
    SalesDayComponent,
    SalesDayWeekComponent,
    SalesHourComponent,
    SalesMonthComponent,
    SalesNavbarComponent,
    SalesArticlesCenterComponent,
    SalesArticlesGeneralComponent,
  ],
})
export class SalesModule {}

@NgModule({
  imports: [SalesRouting],
})
export class SalesRoute {}

@NgModule({
  imports: [SalesDayRouting],
})
export class SalesDayRoute {}

@NgModule({
  imports: [SalesDayWeekRouting],
})
export class SalesDayWeekRoute {}

@NgModule({
  imports: [SalesEmployeeRouting],
})
export class SalesEmployeeRoute {}

@NgModule({
  imports: [SalesMonthRouting],
})
export class SalesMonthRoute {}

@NgModule({
  imports: [SalesHourRouting],
})
export class SalesHourRoute {}

@NgModule({
  imports: [SalesArticleGeneralRouting],
})
export class SalesGeneralRoute {}

@NgModule({
  imports: [SalesArticleCenterRouting],
})
export class SalesCenterRoute {}
