import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserEntity } from "../../interfaces/UserEntity";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserService {
  urlApi: string = "";
  identity: UserEntity = {
    id: 0,
    name: "",
    company: "",
    email: "",
    password: "",
    gettoken: false,
    idempresa: 0,
  };
  token: string = "";

  constructor(public _http: HttpClient) {
    this.urlApi = environment.baseUrlApi;
  }

  signup(user: UserEntity, gettoken: boolean = false): Observable<any> {
    user.gettoken = gettoken;
    const element = {
      params: `json=${JSON.stringify(user)}`,
      header: new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      ),
    };

    return this._http.post<any>(`${this.urlApi}/login`, element.params, {
      headers: element.header,
    });
  }

  getIdentity(): UserEntity {
    const element = {
      condiction:
        localStorage.getItem("identity") &&
        localStorage.getItem("identity") !== undefined,
      jsonParse: JSON.parse(localStorage.getItem("identity") || ""),
    };
    element.condiction
      ? (this.identity = asignIdentity(element.jsonParse))
      : (this.identity = defaultIdentity());

    return this.identity;
  }

  getToken() {
    const element = {
      token: localStorage.getItem("token") || "",
      condiction:
        localStorage.getItem("token") &&
        localStorage.getItem("token") != "undefined",
    };
    return element.condiction
      ? (this.token = element.token)
      : (this.token = "");
  }
}

//getIdentity

function defaultIdentity(): UserEntity {
  return {
    id: 0,
    name: "",
    company: "",
    email: "",
    password: "",
    gettoken: false,
    idempresa: 0,
  };
}

function asignIdentity(identity: any): UserEntity {
  if (identity && identity != "") {
    return identity;
  } else {
    return defaultIdentity();
  }
}
