import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";


import { OrdersListComponent } from "./orders-list/orders-list.component";
import { OrdersDetailComponent } from "./orders-detail/orders-detail.component";

import { OrderDetailRouting, OrderListRouting } from "./order-routes.module";
import { smallComponentsModule } from "src/app/views/smalls-components/smalls-components.module";


@NgModule({
  declarations: [
    OrdersListComponent,
    OrdersDetailComponent,
  ],
  imports: [CommonModule, NgbModule,smallComponentsModule],
  exports: [
    OrdersListComponent,
    OrdersDetailComponent,
  ],
})
export class OrderModule {}

@NgModule({
  imports: [OrderListRouting],
})
export class OrderListRoute {}

@NgModule({
  imports: [OrderDetailRouting],
})
export class OrderDetailRoute {}
