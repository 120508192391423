import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Location } from "@angular/common";
import { Observable } from "rxjs";

import { swalElements, getElements, countryEntity, provinceEntity } from "src/app/interfaces/export-interfaces";

import { ElementService, ClientService, DbService, FormService } from "src/app/services/export-services";
@Component({
  selector: "app-clients-add",
  templateUrl: "./clients-add.component.html",
  styleUrls: ["./clients-add.component.scss"],
})
export class ClientsAddComponent {
  constructor(
    private reactiveForm: FormService,
    private db_service: DbService,
    private service: ClientService,
    private _location: Location,
    private elementView: ElementService,
  ) { }

  addForm: FormGroup = this.reactiveForm.client();

  swalOptions: swalElements = {
    icon: "success",
    title: "Cliente añadido Correctamente",
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
  };

  gets: { [key: string]: getElements } = {
    country: {
      finalUrl: "/Countries",
      token: false,
      data1: "",
      data2: "",
    },
    provinces: {
      finalUrl: "/Provinces/",
      token: false,
      data1: "",
      data2: "",
    },
  };

  countries$: Observable<countryEntity[]> = this.db_service.getData(
    this.gets.country
  );
  provinces$: Observable<provinceEntity[]>;

  //Passive functions

  getProvincesClick() {
    this.gets.provinces.data1 = this.addForm.controls["country"].value;
    this.provinces$ = this.db_service.getData(this.gets.provinces);
  }

  //Active functions

  addClient() {
    this.addForm.valid
      ? (this.service.addClient(this.addForm), this.alert())
      : "";
  }

  private alert() {
    this.elementView
      .alertSwal(this.swalOptions)
      .then(() => this._location.back());
  }
}
