import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SalesArticlesCenterComponent } from "./sales-articles/sales-articles-center/sales-articles-center.component";
import { SalesArticlesGeneralComponent } from "./sales-articles/sales-articles-general/sales-articles-general.component";
import { SalesDayWeekComponent } from "./sales-day-week/sales-day-week.component";
import { SalesDayComponent } from "./sales-day/sales-day.component";
import { SalesEmployeesComponent } from "./sales-employees/sales-employees.component";
import { SalesHourComponent } from "./sales-hour/sales-hour.component";
import { SalesMonthComponent } from "./sales-month/sales-month.component";
import { SalesNavbarComponent } from "./sales-navbar/sales-navbar.component";
import { SalesComponent } from "./sales/sales.component";

const routes: any = {
  sales: [
    {
      path: "",
      component: SalesComponent,
    },
  ],

  salesDay: [
    {
      path: "",
      component: SalesDayComponent,
    },
  ],

  salesDayWeek: [
    {
      path: "",
      component: SalesDayWeekComponent,
    },
  ],

  salesEmployee: [
    {
      path: "",
      component: SalesEmployeesComponent,
    },
  ],

  salesArticlesCenter: [
    {
      path: "",
      component: SalesArticlesCenterComponent,
    },
  ],

  salesArticlesGeneral: [
    {
      path: "",
      component: SalesArticlesGeneralComponent,
    },
  ],

  salesMonth: [
    {
      path: "",
      component: SalesNavbarComponent,
    },
  ],

  salesHour: [
    {
      path: "",
      component: SalesHourComponent,
    },
  ],
};

@NgModule({
  imports: [RouterModule.forChild(routes.sales)],
  exports: [RouterModule],
})
export class SalesRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.salesDay)],
  exports: [RouterModule],
})
export class SalesDayRouting {}


@NgModule({
  imports: [RouterModule.forChild(routes.salesDayWeek)],
  exports: [RouterModule],
})
export class SalesDayWeekRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.salesEmployee)],
  exports: [RouterModule],
})
export class SalesEmployeeRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.salesArticlesCenter)],
  exports: [RouterModule],
})
export class SalesArticleCenterRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.salesArticlesGeneral)],
  exports: [RouterModule],
})
export class SalesArticleGeneralRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.salesMonth)],
  exports: [RouterModule],
})
export class SalesMonthRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.salesHour)],
  exports: [RouterModule],
})
export class SalesHourRouting {}