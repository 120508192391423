import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EmployeesComponent } from "./employee-list/employees.component";
import { AddEmployeeComponent } from "./add-employee/add-employee.component";
import { EditEmployeeComponent } from "./edit-employee/edit-employee.component";
import { smallComponentsModule } from "src/app/views/smalls-components/smalls-components.module";
import {
  EmployeeAddRouting,
  EmployeeEditRouting,
  EmployeeHistoryRouting,
  EmployeeListRouting,
} from "./employee-routes.module";
import { HistoryEmployeeComponent } from "./history-employee/history-employee.component";
import { ControlHistoryEmployeeExternalComponent } from "./control-history-employee-external/control-history-employee-external.component";

@NgModule({
  declarations: [
    EmployeesComponent,
    AddEmployeeComponent,
    EditEmployeeComponent,
    HistoryEmployeeComponent,
    ControlHistoryEmployeeExternalComponent
  ],
  imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule,smallComponentsModule],
  exports: [EmployeesComponent, AddEmployeeComponent, EditEmployeeComponent, HistoryEmployeeComponent,ControlHistoryEmployeeExternalComponent],
})
export class EmployeeModule {}

@NgModule({
  imports: [EmployeeListRouting],
})
export class EmployeeListRoute {}

@NgModule({
  imports: [EmployeeAddRouting],
})
export class EmployeeAddRoute {}

@NgModule({
  imports: [EmployeeEditRouting],
})
export class EmployeeEditRoute {}


@NgModule({
  imports: [EmployeeHistoryRouting],
})
export class EmployeeHistoryRoute {}


