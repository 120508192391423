import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AuthGuard } from "./core/guard/auth.guard";
import { AppComponent } from "./app.component";
import { HIGHLIGHT_OPTIONS } from "ngx-highlightjs";

import { ErrorPageComponent } from "./views/components/error-page/error-page.component";

import { LayoutModule } from "./views/layout/layout.module";

import { LoginModule } from "./views/components/login/login.module";

import { SidebarComponentsModule } from "./views/components/sidebar-components/sidebar-components.module";

import es from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ControlHistoryEmployeeComponent } from './views/components/sidebar-components/employees/control-history-employee/control-history-employee.component';






registerLocaleData(es);

@NgModule({
  declarations: [AppComponent, ErrorPageComponent, ControlHistoryEmployeeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    LoginModule,
    SidebarComponentsModule,
    
  ],

  providers: [
    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import("highlight.js/lib/core"),
        languages: {
          xml: () => import("highlight.js/lib/languages/xml"),
          typescript: () => import("highlight.js/lib/languages/typescript"),
          scss: () => import("highlight.js/lib/languages/scss"),
        },
      },
    },
    { provide: LOCALE_ID, useValue: "es-ES" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
