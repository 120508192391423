import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { OrdersDetailComponent } from "./orders-detail/orders-detail.component";
import { OrdersListComponent } from "./orders-list/orders-list.component";



const routes: any = {
  list: [
    {
      path: "",
      component: OrdersListComponent,
    },
  ],

  detail: [
    {
      path: "",
      component: OrdersDetailComponent,
    },
  ],

};

@NgModule({
  imports: [RouterModule.forChild(routes.list)],
  exports: [RouterModule],
})
export class OrderListRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.detail)],
  exports: [RouterModule],
})
export class OrderDetailRouting {}




