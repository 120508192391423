import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { EmployeeEntity } from "src/app/interfaces/Employee-interfaces/EmployeeEntity";
import { EmployeeHistoryEntity } from "src/app/interfaces/Employee-interfaces/EmployeeHistoryEntity";
import { DbService } from "../../db-service/db.service";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  constructor(private router: Router, private db_service: DbService) {}
  idEmployee: number = 0;

  //datatables content

  employeeHtml(element: any): any[] {
    let type: string;

    element.state == 0
      ? (type = '<span class="badge badge-success">Activo</span>')
      : (type = '<span class="badge badge-danger">Inactivo</span>');

    return [element.code, element.name, type, ""];
  }

  employeeHistoryHtml(element: EmployeeHistoryEntity): any[] {
    return [
      dateFormat(element.FECHA),
      element.TIPO == 1
        ? '<span class="badge badge-success">Entrada</span>'
        : '<span class="badge badge-danger">Salida</span>',
      "<a href='https://www.google.com/maps/@" +
        element.LAT +
        "," +
        element.LON +
        ",17z' target='_blank'>Ver localización</a>",
    ];
  }

  employeeButtons(response: any): any {
    return [
      {
        select: 3,
        render: function (data, cell, row) {
          return (
            data +
            '<a><i title="editar empleado" class="mdi mdi-account-settings" style="font-size:200%" edit-employee id-employee=' +
            response[row.dataIndex].id +
            "></i></a> " +
            '<a><i title="historial empleado" class="mdi mdi-account-details" style="font-size:200%" history-employee id-employee=' +
            response[row.dataIndex].id +
            "></i></a> "
          );
        },
      },
    ];
  }

  //forms

  addEmployee(formValue: FormGroup) {
    this.db_service
      .postData("/Employee/", employeeObject(formValue, "add"))
      .subscribe((res) => {});
  }

  editEmployee(formValue: FormGroup, employee: any) {
    this.db_service
      .putData2(employee, employeeObject(formValue))
      .subscribe((res) => {});
  }

  //buttons events datatable

  getIdEmployee(event: any) {
    if (event.target.hasAttribute("id-employee")) {
      this.idEmployee = event.target.getAttribute("id-employee");
    }
  }

  navigateEditEmployee(event: any) {
    if (event.target.hasAttribute("edit-employee")) {
      this.router.navigate([
        "/employee/editEmployee" + event.target.getAttribute("edit-employee"),
      ]);
    }
  }

  navigateHistoryEmployee(event: any) {
    if (event.target.hasAttribute("history-employee")) {
      this.router.navigate([
        "/employee/historyEmployee" +
          event.target.getAttribute("history-employee"),
      ]);
    }
  }
}

//employeeHistoryHtml

function dateFormat(element: any): string {
  const date = {
    month: monthSwitch(element.split(" ")[0]),
    hours: hoursString(element),
  };
  if (element.split(" ")[1] == "") {
    return (
      element.split(" ")[2] +
      "/" +
      date.month +
      "/" +
      element.split(" ")[3] +
      " - " +
      date.hours
    );
  }
  return (
    element.split(" ")[1] +
    "/" +
    date.month +
    "/" +
    element.split(" ")[2] +
    " - " +
    date.hours
  );
}

//dateFormat

function monthSwitch(element: string): string {
  switch (element) {
    case "Jan":
      return "01";

    case "Feb":
      return "02";

    case "Mar":
      return "03";

    case "Apr":
      return "04";

    case "May":
      return "05";

    case "Jun":
      return "06";

    case "Jul":
      return "07";

    case "Aug":
      return "08";

    case "Sep":
      return "09";

    case "Oct":
      return "10";

    case "Nov":
      return "11";

    case "Dec":
      return "12";
  }
}

function hoursString(fecha: string): string {
  const hoursString = fecha.split(" ").reverse().join(":");

  const hours = hoursString.split(":")[0] + ":" + hoursString.split(":")[1];

  return hours;
}

//addEmployee | editEmployee

function employeeObject(formValue: any, option?: string): EmployeeEntity {
  return {
    codigo: Number(formValue.value.cod),
    nombre: formValue.value.name,
    estado: option == "add" ? 0 : formValue.value.state,
  };
}
