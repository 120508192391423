import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { UserService } from "../auth-service/user.service";

@Injectable({
  providedIn: "root",
})
export class DbService {
  constructor(private _userService: UserService, public _http: HttpClient) {}
  public token = this._userService.getToken();
  public urlApi = environment.baseUrlApi;

  headers = new HttpHeaders().set(
    "Content-Type",
    "application/x-www-form-urlencoded"
  );

  getData(element: any): Observable<any> {
    let url = "";
    const option = {
      token:
        this.urlApi +
        element.finalUrl +
        this.token +
        element.data1 +
        element.data2,
      normal: this.urlApi + element.finalUrl + element.data1 + element.data2,
    };

    element.token == true ? (url = option.token) : (url = option.normal);

    return this._http.get<any>(url, { headers: this.headers });
  }

  postData(finalUrl: string, data: any): Observable<any> {
    const url = this.urlApi + finalUrl + this.token;
    return this._http.post(url, data);
  }

  putData(finalUrl: string, data: any, id: any): Observable<any> {
    const url = this.urlApi + finalUrl + this.token + "/" + id;
    return this._http.put(url, data);
  }

  getData2(element: any): Observable<any> {
    let url =this.urlApi + element.finalUrl +element.data1 + this.token +element.data2;
    return this._http.get<any>(url, { headers: this.headers });
  }

  putData2(element: any, data: any): Observable<any> {
    const url = this.urlApi + element.finalUrl + element.id + this.token;
    return this._http.put(url, data);
  }
}
