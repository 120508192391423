import { Injectable } from "@angular/core";
import { DbService } from "../../db-service/db.service";
import { CalculationsService } from "../../shared/calculations/calculate.service";
import { Observable } from "rxjs";
import { ApexAxisChartSeries, ApexGrid, ApexChart, ApexXAxis, ApexYAxis, ApexMarkers, ApexStroke, ApexLegend, ApexTooltip, 
  ApexDataLabels, ApexFill, ApexPlotOptions, ApexResponsive, ApexNonAxisChartSeries, ApexTitleSubtitle } from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  nonAxisSeries: ApexNonAxisChartSeries;
  colors: string[];
  grid: ApexGrid;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  markers: ApexMarkers;
  stroke: ApexStroke;
  legend: ApexLegend;
  responsive: ApexResponsive[];
  tooltip: ApexTooltip;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  labels: string[];
  title: ApexTitleSubtitle;
};
@Injectable({
  providedIn: "root",
})
export class DashboardService {
  constructor(
    private db_service: DbService,
    private calculate: CalculationsService
  ) {}

  
  year: any;
  getComparative = {
    finalUrl: "/getSalesComparativexMonth/",
    token: true,
    data1: "",
    data2: "",
  };

  getSalesComparativexMonth(grpYear: number): Observable<any> {
    this.getComparative.data1 = "/" + grpYear;
    return this.db_service.getData(this.getComparative);
  }

  fillOptions() {
    return {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    };
  }

  tooltipOptions() {
    return {
      shared: true,
      intersect: false,
      y: [
        {
          formatter: (y) => {
            if (typeof y !== "undefined") {
              if (y == 0) {
                return "";
              }
              return y + "€";
            }
            return y;
          },
        },
        {
          formatter: (y) => {
            if (typeof y !== "undefined") {
              if (y == 0) {
                return "";
              }
              return y + "€";
            }
            return y;
          },
        },
      ],
    };
  }

  plotOpt() {
    return {
      bar: {
        columnWidth: "50%",
      },
    };
  }

  legendOptions(): any {
    return {
      position: "top",
      horizontalAlign: "left",
    };
  }

  strokeOptions(): any {
    return {
      width: [0, 2, 5],
      curve: "smooth",
    };
  }

  chartOptions(): any {
    return {
      height: 280,
      type: "area",
      stacked: false,
      parentHeightOffset: 0,
    };
  }

  gridOptions(): any {
    return {
      borderColor: "rgba(77, 138, 240, .1)",
      padding: {
        bottom: 0,
      },
    };
  }

  labelOptions(): any {
    return [
      "",
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
  }

  seriesOptions(res: any): any {
    return [
      {
        name: String(this.year),
        type: "area",
        data: [
          0,
          this.calculate.formatDashBoard(res.dataCurrent[0]),
          this.calculate.formatDashBoard(res.dataCurrent[1]),
          this.calculate.formatDashBoard(res.dataCurrent[2]),
          this.calculate.formatDashBoard(res.dataCurrent[3]),
          this.calculate.formatDashBoard(res.dataCurrent[4]),
          this.calculate.formatDashBoard(res.dataCurrent[5]),
          this.calculate.formatDashBoard(res.dataCurrent[6]),
          this.calculate.formatDashBoard(res.dataCurrent[7]),
          this.calculate.formatDashBoard(res.dataCurrent[8]),
          this.calculate.formatDashBoard(res.dataCurrent[9]),
          this.calculate.formatDashBoard(res.dataCurrent[10]),
          this.calculate.formatDashBoard(res.dataCurrent[11]),
        ],
      },
      {
        name: String(this.year - 1),
        type: "area",
        data: [
          0,
          this.calculate.formatDashBoard(res.dataBefore[0]),
          this.calculate.formatDashBoard(res.dataBefore[1]),
          this.calculate.formatDashBoard(res.dataBefore[2]),
          this.calculate.formatDashBoard(res.dataBefore[3]),
          this.calculate.formatDashBoard(res.dataBefore[4]),
          this.calculate.formatDashBoard(res.dataBefore[5]),
          this.calculate.formatDashBoard(res.dataBefore[6]),
          this.calculate.formatDashBoard(res.dataBefore[7]),
          this.calculate.formatDashBoard(res.dataBefore[8]),
          this.calculate.formatDashBoard(res.dataBefore[9]),
          this.calculate.formatDashBoard(res.dataBefore[10]),
          this.calculate.formatDashBoard(res.dataBefore[11]),
        ],
      },
    ];
  }
}
