import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { CalculationsService, FileService, DataTableService, DbService, SalesService} from "src/app/services/export-services";
import { elementsExcel, getElements, tableElements,SaleStoreByDayWeek } from "src/app/interfaces/export-interfaces";
@Component({
  selector: "app-sales-day-week",
  templateUrl: "./sales-day-week.component.html",
  styleUrls: ["./sales-day-week.component.scss"],
})
export class SalesDayWeekComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private db_service: DbService,
    private sharedService: FileService,
    private dataTable: DataTableService,
    private service: SalesService,
    private calculate: CalculationsService
  ) {}
 
  subTable: Subscription;
  show$:Observable<boolean>= this.dataTable.showSpinner;
  getSales:getElements = {
    finalUrl: "/getSalesStorexDayWeek/",
    token: true,
    data1: "",
    data2: "",
  };

  componentsTable:tableElements = {
    url: this.db_service.getData(this.getSales),
    id: "#dataTable",
    html: this.service.dayWeekSalesHtml,
  };

  excel:elementsExcel = {
    url: this.db_service.getData(this.getSales),
    name: "ventas_por_dias",
  };

  ngOnInit(): void {
    this.createTable();
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.createTable();
  }

  public sum(key: keyof SaleStoreByDayWeek): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
      
    });
  }

  resetTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.resetTable(this.componentsTable.html, response);
    });
  }
  reloadTable() {
    this.dataTable.dataTable.clear()
    this.dataTable.showSpinner.next(true)
    this.componentsTable.url = this.db_service.getData(this.getSales);

    this.resetTable();
  }
}
