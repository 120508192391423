import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-sales-navbar",
  templateUrl: "./sales-navbar.component.html",
  styleUrls: ["./sales-navbar.component.scss"],
})
export class SalesNavbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  articles = true;

  center() {
    this.articles = true;
  }

  general() {
    this.articles = false;
  }
}
