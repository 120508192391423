import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CatalogStockStoreComponent } from "./catalog-stock-store/catalog-stock-store.component";
import { CatalogStockWarehouseComponent } from "./catalog-stock-warehouse/catalog-stock-warehouse.component";

const router:any={
  store:[
    {
      path: "",
      component: CatalogStockStoreComponent,
    },
  ],
  warehouse:[
    {
      path: "",
      component: CatalogStockWarehouseComponent,
    },
  ],
  }

@NgModule({
  imports: [RouterModule.forChild(router.store)],
  exports: [RouterModule],
})
export class CatalogRoutingStore {}

@NgModule({
  imports: [RouterModule.forChild(router.warehouse)],
  exports: [RouterModule],
})
export class CatalogRoutingWareHouse {}
