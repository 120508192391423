import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";


import { CatalogStockStoreComponent } from "./catalog-stock-store/catalog-stock-store.component";
import { CatalogStockWarehouseComponent } from "./catalog-stock-warehouse/catalog-stock-warehouse.component";

import { CatalogRoutingStore, CatalogRoutingWareHouse } from "./catalog-routes.module";
import { smallComponentsModule } from "src/app/views/smalls-components/smalls-components.module";

@NgModule({
  declarations: [
    CatalogStockStoreComponent,
    CatalogStockWarehouseComponent,
  ],
  imports: [CommonModule, NgbModule,smallComponentsModule],
  exports: [
    CatalogStockStoreComponent,
    CatalogStockWarehouseComponent,
  ],
})
export class CatalogModule {}

@NgModule({
  imports: [CatalogRoutingStore],
})
export class StoreRoute {}

@NgModule({
  imports: [CatalogRoutingWareHouse],
})
export class WareHouseRoute {}



