import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { DbService,ClientService } from "src/app/services/export-services";
import { clientEntity, getElements } from "src/app/interfaces/export-interfaces";

@Component({
  selector: "app-clients-details",
  templateUrl: "./clients-details.component.html",
  styleUrls: ["./clients-details.component.scss"],
})
export class ClientsDetailsComponent implements OnInit,OnDestroy {
  constructor(private service: ClientService, private db_service: DbService) {}


  getClient:getElements = {
    finalUrl: "/Customer/",
    token: true,
    data1: "/" + this.service.idClient,
    data2: "",
  };
  client$: Observable<clientEntity> = this.db_service.getData(this.getClient);
  client!: clientEntity;
  sub: Subscription;

  ngOnInit(): void {
   this.sub= this.client$.subscribe((res) => (this.client = res[0]));
  }
  ngOnDestroy(): void {
   this.sub.unsubscribe();
  }
}
