import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { elementsExcel, getElements, tableElements } from "src/app/interfaces/export-interfaces";
import { CatalogService, DbService, CalculationsService, DataTableService, FileService, ElementService, UserService } from "src/app/services/export-services";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-catalog-stock-store",
  templateUrl: "./catalog-stock-store.component.html",
  styleUrls: ["./catalog-stock-store.component.scss"],
})
export class CatalogStockStoreComponent
  implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public _http: HttpClient,
    private dataTable: DataTableService,
    private service: CatalogService,
    private renderer: Renderer2,
    private sharedService: FileService,
    private db_service: DbService,
    private calculate: CalculationsService,
    private elementView: ElementService,
    public _userService: UserService,
  ) { }

  show$: Observable<boolean> = this.dataTable.showSpinner;

  subTable: Subscription;

  get: getElements = {
    finalUrl: "/getStockStore3/",
    token: true,
    data1: "",
    data2: "",
  };

  componentsTable: tableElements = {
    url: this.db_service.getData(this.get),
    id: "#dataTable",
    html: this.service.catalogCenterHtml,
    actions: this.service.orderButtons,
  };

  excel: elementsExcel = {
    url: this.db_service.getData(this.get),
    name: "catalogo_centro",
  };

  ngOnInit(): void {
    this.createTable();
  }

  ngAfterViewInit(): void {
    this.renderer.listen("document", "click", (event) => {
      this.service.getIdStore(event);

      this.service.navigateDetailOrder(event);
    });
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }

  //Passive functions
  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }

  sum(key: keyof any): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  //Active functions
  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }

  downloadCatalog() {
    let token = this._userService.getToken();

    const target = event.target as HTMLInputElement;
    const totalFile = target.files?.length == null ? 0 : target.files?.length;
    let mens = "";
    for (let nFile = 0; nFile < totalFile || 0; nFile++) {
      let file: File = (target.files as FileList)[nFile];
      if (file) {
        let fileName = file.name;
        let extFile = fileName.slice(-3);
        if (extFile === "csv") {
          let formData = new FormData();
          formData.append("file_csv", file);

          this._http.post<any>(environment.baseUrlApi + "/pedido/alta/" + token + "/csv", formData).subscribe({
            next: data => {
              this.elementView.alertSwal(
                {
                  icon: data.error == true ? "error" : "success",
                  title: data.message == "ok" ? "Pedido procesado Correctamente" : data.message,
                  confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar'
                }
              ).then(() => {
                  if(data.error != true) { location.reload(); }
                }
              );
            },
            error: error => {
              this.elementView.alertSwal({
                icon: "error",
                title: error.error.message,
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
              });
            }
        })
        } else {
          this.elementView.alertSwal({
            icon: "error",
            title: 'Solo se admiten archivos CSV. Has subido un archivo ' + extFile,
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
          });
        }
      }
    }   
  }
}
