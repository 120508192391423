import { Injectable } from "@angular/core";
import { SaleItems } from "src/app/interfaces/Sales-interfaces/SaleItems";
import { SaleItemsByStore } from "src/app/interfaces/Sales-interfaces/SaleItemsByStore";
import { SalesDay } from "src/app/interfaces/Sales-interfaces/SalesDay";
import { SalesEmployee } from "src/app/interfaces/Sales-interfaces/SalesEmployee";
import { SaleStoreByDayWeek } from "src/app/interfaces/Sales-interfaces/SaleStoreByDayWeek";
import { SaleStoreByHour } from "src/app/interfaces/Sales-interfaces/SaleStoreByHour";
import { SaleStoreMonth } from "src/app/interfaces/Sales-interfaces/SaleStoreMonth";
import { SalesTotal } from "src/app/interfaces/Sales-interfaces/SaleTotal";

const result = new Intl.NumberFormat("es", {
  style: "currency",
  currency: "EUR",
});

@Injectable({
  providedIn: "root",
})
export class SalesService {
  constructor() {}

  //datatables content

  monthSalesHtml(element: SaleStoreMonth): any[] {
    return [
      element.store,
      result.format(element.month01),
      result.format(element.month02),
      result.format(element.month03),
      result.format(element.month04),
      result.format(element.month05),
      result.format(element.month06),
      result.format(element.month07),
      result.format(element.month08),
      result.format(element.month09),
      result.format(element.month10),
      result.format(element.month11),
      result.format(element.month12),
      result.format(element.total),
    ];
  }

  monthSalesHtml2(element: SaleStoreMonth): any[] {
    return [
      element.nrow,
      element.store,
      Math.trunc(element.month01),
      Math.trunc(element.month02),
      Math.trunc(element.month03),
      Math.trunc(element.month04),
      Math.trunc(element.month05),
      Math.trunc(element.month06),
      Math.trunc(element.month07),
      Math.trunc(element.month08),
      Math.trunc(element.month09),
      Math.trunc(element.month10),
      Math.trunc(element.month11),
      Math.trunc(element.month12),
      Math.trunc(element.total),
    ];
  }

  daySalesHtml(element: SalesDay): any[] {
    return [
      element.nrow,
      element.store,
      element.totalDay,
      element.quantity,
      result.format(element.amount),
      element.dayWithoutSales,
    ];
  }

  totalSalesHtml(element: SalesTotal): any[] {
    return [
      element.store,
      element.nticket,
      element.quantity,
      result.format(element.amount),
      Number(element.upt).toFixed(2),
      Number(element.average_price).toFixed(2),
    ];
  }

  employeeSalesHtml(element: SalesEmployee): any[] {
    return [
      element.nrow,
      element.store,
      element.idemployee,
      element.name,
      element.nticket,
      element.quantity,
      result.format(element.amount),
      Number(element.upt).toFixed(2),
      Number(element.avarage_price).toFixed(2),
    ];
  }

  generalSalesHtml(element: SaleItems): any[] {
    return [
      element.nrow,
      element.code,
      element.description,
      element.quantity,
      result.format(element.amount),
    ];
  }

  centerSalesHtml(element: SaleItemsByStore): any[] {
    return [
      element.nrow,
      element.store,
      element.code,
      element.description,
      element.quantity,
      result.format(element.amount),
    ];
  }

  hourSalesHtml(element: SaleStoreByHour): any[] {
    return [
      element.nrow,
      element.store,
      result.format(element.hour10),
      result.format(element.hour11),
      result.format(element.hour12),
      result.format(element.hour13),
      result.format(element.hour14),
      result.format(element.hour15),
      result.format(element.hour16),
      result.format(element.hour17),
      result.format(element.hour18),
      result.format(element.hour19),
      result.format(element.hour20),
      result.format(element.hour21),
      result.format(element.hour22),
      result.format(element.total),
    ];
  }

  dayWeekSalesHtml(element: SaleStoreByDayWeek): any[] {
    return [
      element.nrow,
      element.store,
      result.format(element.day_01),
      result.format(element.day_02),
      result.format(element.day_03),
      result.format(element.day_04),
      result.format(element.day_05),
      result.format(element.day_06),
      result.format(element.day_07),
      result.format(element.total),
    ];
  }
}
