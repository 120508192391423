import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { getElements, tableElements } from "src/app/interfaces/export-interfaces";
import { DataTableService,EmployeeService,DbService } from "src/app/services/export-services";

@Component({
  selector: "app-history-employee",
  templateUrl: "./history-employee.component.html",
  styleUrls: ["./history-employee.component.scss"],
})
export class HistoryEmployeeComponent implements OnInit {
  constructor(
    private db_service: DbService,
    private service: EmployeeService,
    private dataTable: DataTableService
  ) {}
  get:getElements = {
    finalUrl: "/EmployeeHistory/",
    token: true,
    data1: this.service.idEmployee + "/",
    data2: "",
  };
  show:boolean=false;
  sub: Subscription;
  subTable: Subscription;
  componentsTable:tableElements = {
    url: this.db_service.getData2(this.get),
    id: "#dataTable",
    html: this.service.employeeHistoryHtml,
    order: "id",
  };

  ngOnInit(): void {
    this.createTable();
    this.sub = this.dataTable.showSpinner.subscribe((res) => (this.show = res));
  }
  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }
}
