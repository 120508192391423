import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Location } from "@angular/common";
import { Observable } from "rxjs";
import { ClientService, DbService, FormService, ElementService } from "src/app/services/export-services";
import { clientEntity,countryEntity,getElements,provinceEntity,swalElements } from "src/app/interfaces/export-interfaces";

@Component({
  selector: "app-clients-edit",
  templateUrl: "./clients-edit.component.html",
  styleUrls: ["./clients-edit.component.scss"],
})
export class ClientsEditComponent implements OnInit {
  constructor(
    private reactiveForm: FormService,
    private db_service: DbService,
    private service: ClientService,
    private _location: Location,
    private elementView: ElementService
  ) {
    this.reactiveForm.valueEditForm2(this.editForm, this.client$);
  }

  editForm: FormGroup = this.reactiveForm.client();
  swalOptions: swalElements = {
    icon: "success",
    title: "Cliente Modificado Correctamente",
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
  };

  gets:{ [key: string]: getElements } = {
    country: {
      finalUrl: "/Countries",
      token: false,
      data1: "",
      data2: "",
    },
    client: {
      finalUrl: "/Customer/",
      token: true,
      data1: "/" + this.service.idClient,
      data2: "",
    },
    clients: {
      finalUrl: "/Customer/",
      token: true,
      data1: "",
      data2: "",
    },
    provinces: {
      finalUrl: "/Provinces/",
      token: false,
      data1: "",
      data2: "",
    },
  };

  countries$: Observable<countryEntity[]> = this.db_service.getData(
    this.gets.country
  );

  provinces$: Observable<provinceEntity[]>;

  client$: Observable<clientEntity[]> = this.db_service.getData(
    this.gets.client
  );

  ngOnInit(): void {
    this.getProvinces();
  }

  getProvinces() {
    this.gets.provinces.data1 = this.reactiveForm.idCountry;
    this.provinces$ = this.db_service.getData(this.gets.provinces);
  }

  getProvincesClick() {
    this.gets.provinces.data1 = this.editForm.controls.country.value;
    this.provinces$ = this.db_service.getData(this.gets.provinces);
  }

  editClient() {
    this.editForm.valid
      ? (this.service.editClient(this.editForm, this.service.idClient),
        this.alert()) : "";
  }

  private alert() {
    this.elementView.alertSwal(this.swalOptions).then(() => this._location.back());
  }
}
