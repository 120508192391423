import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ElementService, FormService, DbService, EmployeeService } from "src/app/services/export-services";
import { Location } from "@angular/common";
import { getElements, EmployeeEntity, swalElements, } from "src/app/interfaces/export-interfaces";

@Component({
  selector: "app-edit-employee",
  templateUrl: "./edit-employee.component.html",
  styleUrls: ["./edit-employee.component.scss"],
})
export class EditEmployeeComponent implements OnInit {
  constructor(
    private service: EmployeeService,
    private db_service: DbService,
    private reactiveForm: FormService,
    private elementView: ElementService,
    private _location: Location
  ) { }

  editForm: FormGroup = this.reactiveForm.employee();
  employee: EmployeeEntity = { codigo: 0, nombre: '', estado: 0, id: 0 };
  getEmployee: getElements = {
    finalUrl: "/Employee/",
    token: true,
    data1: this.service.idEmployee + "/",
    data2: "",
  };

  gets: { [key: string]: getElements } = {
    employee: {
      finalUrl: "/Employee/",
      token: true,
      id: this.service.idEmployee + "/",
      data1: "",
      data2: "",
    },
  };

  swalOptions: swalElements = {
    icon: "success",
    title: "Empleado Modificado Correctamente",
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
  };
  ngOnInit(): void {
    this.getEmploye();
  }

  getEmploye() {
    this.db_service.getData2(this.getEmployee).subscribe((res) => {
      this.employee = res[0];
      this.reactiveForm.valueEditEmployeeForm(this.editForm, this.employee);
    });
  }

  editEmployee() {
    if (this.editForm.valid) {
      this.service.editEmployee(this.editForm, this.gets.employee);
      this.elementView.alertSwal(this.swalOptions).then((res) => {
        this._location.back();
      });
    }
  }
}
