import { Injectable } from "@angular/core";
import { BehaviorSubject} from "rxjs";
import { DataTable } from "simple-datatables";

@Injectable({
  providedIn: "root",
})
export class DataTableService {
  constructor() {}
  htmlTable: any = [];
  arrayTable: string[] = [];
  dataTable: any;
  label: any = {
    placeholder: "Buscar",
    perPage: "{select}",
    noRows: "Sin registro",
    info: "{start} - {end} de {rows} (Página {page} a {pages})",
  };
  sortColumn: any = 0;
  sortDirection: any = "desc";
  showSpinner: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /*
createTable(element: any) {
   element.url.subscribe((response) => {
      this.fillTable(element.html, response, element.order);

      this.selectDatatable(element, response);

     
    });
   
    
  }
*/
  selectDatatable(element: any, response: any) {
    element.actions !== undefined
      ? this.dataTableWithButtons(element, response)
      : this.dataTableWithoutButtons(element.id);
    this.htmlTable = [];
  }

  fillTable(html:any, response: any, order: any) {
    response == null ? this.showSpinner.next(false) : "";
    this.sortColumn = order != "" ?  order : -1;
   //const array = response.sort(this.orderBy(order));
   
    this.htmlTable = response.map((value: any) => html(value));

    this.arrayTable = response;
    this.showSpinner.next(false);
  }

  resetTable(html: any, response: any) {
    response == null ? this.showSpinner.next(false) : "";
    this.dataTable.rows().remove(this.arrayTable);

    const array = response.map((value: any) => html(value));

    this.dataTable.rows().add(array);
    
    this.arrayTable = response;
    this.showSpinner.next(false);
  }

  orderBy(id: any): any {
    switch (id) {
      case "id":
        return (a: any, b: any) => (-1);
      default:
        return undefined;
    }
  }

  private dataTableWithButtons(element: any, response: any) {
    this.dataTable = new DataTable(element.id, {
      data: { data: this.htmlTable },
      labels: this.label,
      columns: element.actions(response),
      footer: true,
      header: true,
    });
    this.dataTable.columns().sort(this.sortColumn, "desc");
  }

  private dataTableWithoutButtons(id: any) {
    this.dataTable = new DataTable(id, {
      data: { data: this.htmlTable },
      labels: this.label,
      footer: true,
      header: true,
    });
  }

  /* POSIBLE CAMBIO DATOS CON UN JSON*/
  /*
  createTable2(url: any, id?: any) {
    url.subscribe((response) => {
      new DataTable(id, {
        labels: this.label,
        footer: true,
        header: true,
      }).import({
        type: "json",
        data: JSON.stringify(response),
      });

      this.arrayTable = response;
    });
  }*/
}
