import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgApexchartsModule } from "ng-apexcharts";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardCardsComponent } from "./dashboard-cards/dashboard-cards.component";
import { DashboardGraphicComponent } from "./dashboard-graphic/dashboard-graphic.component";
import { DashboardRouting } from "./dashboard-routes.module";

@NgModule({
  declarations: [DashboardCardsComponent, DashboardGraphicComponent],
  imports: [CommonModule, NgbModule,NgApexchartsModule],
  exports: [DashboardCardsComponent, DashboardGraphicComponent],
})
export class DashboardModule {}

@NgModule({
  imports: [DashboardRouting],
})
export class DashboardRoute {}
