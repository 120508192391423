import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AddUserComponent } from "./add-user/add-user.component";
import { EditUserComponent } from "./edit-user/edit-user.component";
import { ListUsersComponent } from "./list-users/list-users.component";


const routes: any = {
  list: [
    {
      path: "",
      component: ListUsersComponent,
    },
  ],

  add: [
    {
      path: "",
      component: AddUserComponent,
    },
  ],

  edit: [
    {
      path: "",
      component: EditUserComponent,
    },
  ],

};

@NgModule({
  imports: [RouterModule.forChild(routes.list)],
  exports: [RouterModule],
})
export class UserListRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.add)],
  exports: [RouterModule],
})
export class UserAddRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.edit)],
  exports: [RouterModule],
})
export class UserEditRouting {}


