import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseComponent } from "./views/layout/base/base.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { ErrorPageComponent } from "./views/components/error-page/error-page.component";
import { LoginComponent } from "./views/components/login/login.component";
import { ControlHistoryEmployeeExternalComponent } from "./views/components/sidebar-components/employees/control-history-employee-external/control-history-employee-external.component";


const url = {
  catalog: "./views/components/sidebar-components/catalog/catalog.module",
  client: "./views/components/sidebar-components/clients/client.module",
  user: "./views/components/sidebar-components/users/user.module",
  employee: "./views/components/sidebar-components/employees/employee.module",
  order: "./views/components/sidebar-components/orders/order.module",
  sales:"./views/components/sidebar-components/sales/sales.module",
  dashboard:"./views/components/sidebar-components/dashboard/dashboard.module"
};

const routesComponent = {
  catalog: {
    store: {
      path: "catalog",
      loadChildren: () =>
        import("" + url.catalog + "").then((m) => m.StoreRoute),
    },

    warehouse: {
      path: "catalog/warehouse",
      loadChildren: () =>
        import("" + url.catalog + "").then((m) => m.WareHouseRoute),
    },
  },
  client: {
    list: {
      path: "clients",
      loadChildren: () =>
        import("" + url.client + "").then((m) => m.ClientListRoute),
    },

    add: {
      path: "clients/addClient",
      loadChildren: () =>
        import("" + url.client + "").then((m) => m.ClientAddRoute),
    },

    detail: {
      path: "clients/detailClient",
      loadChildren: () =>
        import("" + url.client + "").then((m) => m.ClientDetailRoute),
    },

    edit: {
      path: "clients/editClient",
      loadChildren: () =>
        import("" + url.client + "").then((m) => m.ClientEditRoute),
    },
  },
  user: {
    list: {
      path: "user",
      loadChildren: () =>
        import("" + url.user + "").then((m) => m.UserListRoute),
    },

    add: {
      path: "user/addUser",
      loadChildren: () =>
        import("" + url.user + "").then((m) => m.UserAddRoute),
    },

    edit: {
      path: "user/editUser",
      loadChildren: () =>
        import("" + url.user + "").then((m) => m.UserEditRoute),
    },
  },
  employee: {
    list: {
      path: "employee",
      loadChildren: () =>
        import("" + url.employee + "").then((m) => m.EmployeeListRoute),
    },

    add: {
      path: "employee/addEmployee",
      loadChildren: () =>
        import("" + url.employee + "").then((m) => m.EmployeeAddRoute),
    },

    history: {
      path: "employee/historyEmployee",
      loadChildren: () =>
        import("" + url.employee + "").then((m) => m.EmployeeHistoryRoute),
    },

    edit: {
      path: "employee/editEmployee",
      loadChildren: () =>
        import("" + url.employee + "").then((m) => m.EmployeeEditRoute),
    },

  },
  order: {
    list: {
      path: "orders",
      loadChildren: () =>
        import("" + url.order + "").then((m) => m.OrderListRoute),
    },

    detail: {
      path: "orders/orderDetail",
      loadChildren: () =>
        import("" + url.order + "").then((m) => m.OrderDetailRoute),
    },
  },
  sales: {
    sales: {
      path: "sales/total",
      loadChildren: () =>
        import("" + url.sales + "").then((m) => m.SalesRoute),
    },

    salesDay: {
      path: "sales/diary",
      loadChildren: () =>
        import("" + url.sales + "").then((m) => m.SalesDayRoute),
    },

    salesDayWeek: {
      path: "sales/day",
      loadChildren: () =>
        import("" + url.sales + "").then((m) => m.SalesDayWeekRoute),
    },

    salesEmployee: {
      path: "sales/employee",
      loadChildren: () =>
        import("" + url.sales + "").then((m) => m.SalesEmployeeRoute),
    },

    salesHour: {
      path: "sales/hours",
      loadChildren: () =>
        import("" + url.sales + "").then((m) => m.SalesHourRoute),
    },
    
    salesMonth: {
      path: "sales",
      loadChildren: () =>
        import("" + url.sales + "").then((m) => m.SalesMonthRoute),
    },

   
  },
  dashboard:{
    dashboard: {
      path: "dashboard",
      loadChildren: () =>
        import("" + url.dashboard + "").then((m) => m.DashboardRoute),
    },
  }
};

const routes: Routes = [
  { path: "login", component: LoginComponent },

  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],

    children: [
      routesComponent.user.list,

      routesComponent.user.add,

      routesComponent.user.edit,

      routesComponent.employee.list,

      routesComponent.employee.edit,

      routesComponent.employee.add,

      routesComponent.employee.history,

      routesComponent.catalog.store,

      routesComponent.catalog.warehouse,

      routesComponent.client.list,

      routesComponent.client.add,

      routesComponent.client.detail,

      routesComponent.client.edit,

      routesComponent.order.list,

      routesComponent.order.detail,

      routesComponent.sales.sales,

      routesComponent.sales.salesDay,

      routesComponent.sales.salesDayWeek,

      routesComponent.sales.salesEmployee,

      routesComponent.sales.salesHour,

      routesComponent.sales.salesMonth,

      routesComponent.dashboard.dashboard,

      { path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],

   

  },
  { path: "presenceControl", component: ControlHistoryEmployeeExternalComponent },
  {
    path: "error",
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: "Page Not Found",
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: "error/:type",
    component: ErrorPageComponent,
  },
  { path: "**", redirectTo: "error", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
