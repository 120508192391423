import { HttpClient } from "@angular/common/http";
import { Observable, Subscription } from "rxjs";
import { Component, OnDestroy, OnInit } from "@angular/core";

import { CalculationsService, UserService, FileService, DataTableService, DbService, SalesService } from "src/app/services/export-services";
import { elementsExcel, getElements,StadistictStoreEntity, tableElements } from "src/app/interfaces/export-interfaces";

@Component({
  selector: "app-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.scss"],
})
export class SalesComponent implements OnInit, OnDestroy {
  constructor(
    public _http: HttpClient,
    public _userService: UserService,
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: SalesService,
    private sharedService: FileService,
    private calculate: CalculationsService
  ) {}


  subTable: Subscription;
  show$:Observable<boolean>= this.dataTable.showSpinner;
  getStadistic:getElements = {
    finalUrl: "/getStadistictStore/",
    token: true,
    data1: "/" + this.calculate.Date().startDate + "/",
    data2: this.calculate.Date().endDate,
  };

  componentsTable:tableElements = {
    url: this.db_service.getData(this.getStadistic),
    id: "#dataTable",
    html: this.service.totalSalesHtml,
  };

  excel:elementsExcel = {
    url: this.db_service.getData(this.getStadistic),
    name: "ventas_total",
  };

  ngOnInit(): void {
    this.createTable();
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }

 sum(key: keyof StadistictStoreEntity): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  average(
    keyA: keyof StadistictStoreEntity,
    keyB: keyof StadistictStoreEntity
  ): number {
    return this.calculate.average(keyA, keyB, this.dataTable.arrayTable);
  }

  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }


  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, ""),
        this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }

  resetTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.resetTable(this.componentsTable.html, response);
    });
  }

  searchDate() {
    this.dataTable.dataTable.clear()
    this.dataTable.showSpinner.next(true)

    this.getStadistic.data1 = this.rangeDate().range1;
    this.getStadistic.data2 = this.rangeDate().range2;
    this.componentsTable.url = this.db_service.getData(this.getStadistic);

    this.resetTable();
  }

  private rangeDate():any{
   return {
      range1:
        "/" +
        this.calculate.date1.year +
        "-" +
        this.calculate.date1.month +
        "-" +
        this.calculate.date1.day +
        "/",
      range2:
        this.calculate.date2.year +
        "-" +
        this.calculate.date2.month +
        "-" +
        this.calculate.date2.day,
    }
  }

  reloadTable() {
    this.dataTable.dataTable.clear()
    this.dataTable.showSpinner.next(true)
    this.getStadistic.data1 = "/" + this.calculate.Date().startDate + "/";
    this.getStadistic.data2 = this.calculate.Date().endDate;
    this.componentsTable.url = this.db_service.getData(this.getStadistic);

    this.resetTable();
  }

 
}
