import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DashboardCardsComponent } from "./dashboard-cards/dashboard-cards.component";




const routes: any = {
  dashboard: [
    {
      path: "",
      component: DashboardCardsComponent,
    },
  ],

};

@NgModule({
  imports: [RouterModule.forChild(routes.dashboard)],
  exports: [RouterModule],
})
export class DashboardRouting {}




