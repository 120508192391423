import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserAppService {
  constructor(private router: Router) {}
  idUser: number = 0;
  /*
Cuerpo de la tabla (datatables) para el componente Users
*/
  userHtml(element: any): any[] {
    let type;
    if (element.state == 0) {
      type = '<span class="badge badge-success">Activo</span>';
    } else {
      type = '<span class="badge badge-danger">Inactivo</span>';
    }

    return [
      element.id,
      element.code,
      element.name,
      element.name,
      element.name,
      type,
      "",
    ];
  }

  userButtons(response: any): any {
    return [
      {
        select: 6,
        render: function (data, cell, row) {
          return (
            data +
            '<a><i title="editar empleado" class="mdi mdi-account-settings" style="font-size:200%" edit-user id-user=' +
            response[row.dataIndex].id +
            "></i></a> " +
            '<a><i  title="borrar empleado" placement="top" ngbTooltip="Detalle Cliente"  class="mdi mdi-account-remove" style="font-size:200%;" delete-user id-user=' +
            response[row.dataIndex].id +
            "></i></a> "
          );
        },
      },
    ];
  }

  getIdUser(event: any) {
    if (event.target.hasAttribute("id-User")) {
      this.idUser = event.target.getAttribute("id-user");
    }
  }
  navigateEditUser(event: any) {
    if (event.target.hasAttribute("edit-user")) {
      this.router.navigate([
        "/user/editUser" + event.target.getAttribute("edit-user"),
      ]);
    }
  }
}
