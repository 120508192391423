import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { UserAppService } from "src/app/services/component-service/user/user.service";
import { DbService } from "src/app/services/db-service/db.service";
import { DataTableService } from "src/app/services/shared/datatables/datatables.service";
import { FileService } from "src/app/services/shared/files/files.service";
import { ElementService } from "src/app/services/shared/visible-elements/elements.service";
@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit, AfterViewInit {
  constructor(
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: UserAppService,
    private renderer: Renderer2,
    private router: Router,
    private sharedService: FileService,
    private elementView:ElementService
  ) {}

  get = {
    finalUrl:"/getListEmployee/",
    token: true,
    data1:'',
    data2:''
  };
  componentsTable = {
    url: this.db_service.getData(this.get),
    id: "#dataTable",
    html: this.service.userHtml,
    actions: this.service.userButtons,
  };

  swalOptions = {
    icon: "warning",
    title: "Estas Seguro?",
    showCancelButton: true,
    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Aceptar',
    cancelButtonText: '<i class="fa fa-thumbs-up"></i> Cancelar',
  };
  ngOnInit(): void {
  //  this.dataTable.createTable(this.componentsTable);
  }

  ngAfterViewInit(): void {
    /*Nos permite asignar funciones a los botones,etc de los datatables*/
    this.renderer.listen("document", "click", (event) => {
      if (event.target.hasAttribute("delete-user")) {
        this.delete();
      }
      this.service.getIdUser(event);
      this.service.navigateEditUser(event);
    });
  }

  delete() {
    this.elementView.alertSwal(this.swalOptions).then((res) => {
      res.isConfirmed == true ? console.log("HOLAA"):'';
    });
  }

  goAdd() {
    this.router.navigate(["user/addUser"]);
  }
}
