import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ClientsAddComponent } from "./clients-add/clients-add.component";
import { ClientsDetailsComponent } from "./clients-details/clients-details.component";
import { ClientsEditComponent } from "./clients-edit/clients-edit.component";
import { ClientsListComponent } from "./clients-list/clients-list.component";

const routes: any = {
  list: [
    {
      path: "",
      component: ClientsListComponent,
    },
  ],

  add: [
    {
      path: "",
      component: ClientsAddComponent,
    },
  ],

  edit: [
    {
      path: "",
      component: ClientsEditComponent,
    },
  ],

  detail: [
    {
      path: "",
      component: ClientsDetailsComponent,
    },
  ],
};

@NgModule({
  imports: [RouterModule.forChild(routes.list)],
  exports: [RouterModule],
})
export class ClientListRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.add)],
  exports: [RouterModule],
})
export class ClientAddRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.edit)],
  exports: [RouterModule],
})
export class ClientEditRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.detail)],
  exports: [RouterModule],
})
export class ClientDetailRouting {}
