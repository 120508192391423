import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AddEmployeeComponent } from "./add-employee/add-employee.component";
import { EditEmployeeComponent } from "./edit-employee/edit-employee.component";
import { EmployeesComponent } from "./employee-list/employees.component";
import { HistoryEmployeeComponent } from "./history-employee/history-employee.component";



const routes: any = {
  list: [
    {
      path: "",
      component: EmployeesComponent,
    },
  ],

  add: [
    {
      path: "",
      component: AddEmployeeComponent,
    },
  ],

  edit: [
    {
      path: "",
      component: EditEmployeeComponent,
    },
  ],
  history:[
    {
      path:"",
      component: HistoryEmployeeComponent
    }
  ]

};

@NgModule({
  imports: [RouterModule.forChild(routes.list)],
  exports: [RouterModule],
})
export class EmployeeListRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.add)],
  exports: [RouterModule],
})
export class EmployeeAddRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.edit)],
  exports: [RouterModule],
})
export class EmployeeEditRouting {}

@NgModule({
  imports: [RouterModule.forChild(routes.history)],
  exports: [RouterModule],
})
export class EmployeeHistoryRouting {}




