import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EmployeeModule } from "./employees/employee.module";
import { SalesModule } from "./sales/sales.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { ClientModule } from "./clients/client.module";
import { CatalogModule } from "./catalog/catalog.module";
import { OrderModule } from "./orders/order.module";
import { UserModule } from "./users/user.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EmployeeModule,
    SalesModule,
    DashboardModule,
    ClientModule,
    CatalogModule,
    OrderModule,
    UserModule,
  ],
  exports: [
    EmployeeModule,
    SalesModule,
    DashboardModule,
    ClientModule,
    CatalogModule,
    OrderModule,
    UserModule,
  ],
})
export class SidebarComponentsModule {}
