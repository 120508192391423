import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { UserEntity } from 'src/app/interfaces/UserEntity';
import { UserService } from 'src/app/services/auth-service/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit,OnDestroy {

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private router: Router,
    private userLogin: UserService
  ) { }
  public identity: UserEntity = {
    id: 0,
    name: '',
    company: '',
    email: '',
    password: '',
    gettoken: false,
    idempresa:0
  };
  ngOnInit(): void {
    this.identity = this.userLogin.getIdentity();
  }


  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  ngOnDestroy(): void {
    location.reload();
  }

  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('identity');
    localStorage.removeItem('token');
    if (!localStorage.getItem('isLoggedin') && !localStorage.getItem('identity') && !localStorage.getItem('token')) {
      this.router.navigate(['/login']);
    }
  }

}
