import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";

import { CalculationsService, FileService, DataTableService, DbService, SalesService, UserService } from "src/app/services/export-services";
import { elementsExcel, getElements, tableElements, SalesDay } from "src/app/interfaces/export-interfaces";
@Component({
  selector: "app-sales-day",
  templateUrl: "./sales-day.component.html",
  styleUrls: ["./sales-day.component.scss"],
})
export class SalesDayComponent implements OnInit, OnDestroy {
  constructor(
    public _http: HttpClient,
    public _userService: UserService,
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: SalesService,
    private sharedService: FileService,
    private calculate: CalculationsService
  ) { }

  subTable: Subscription;
  show$: Observable<boolean> = this.dataTable.showSpinner;
  getSales: getElements = {
    finalUrl: "/getSalesxDayxStore/",
    token: true,
    data1: "",
    data2: "",
  };

  componentsTable: tableElements = {
    url: this.db_service.getData(this.getSales),
    id: "#dataTable",
    html: this.service.daySalesHtml,
  };

  excel: elementsExcel = {
    url: this.db_service.getData(this.getSales),
    name: "ventas_diarias",
  };

  ngOnInit(): void {
    this.createTable();
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }

  public sum(key: keyof SalesDay): number {
    return this.calculate.sum(key, this.dataTable.arrayTable);
  }

  downloadExcel() {
    this.sharedService.downloadExcel(this.excel);
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }

  resetTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.resetTable(this.componentsTable.html, response);
    });
  }
  reloadTable() {
    this.dataTable.dataTable.clear()
    this.dataTable.showSpinner.next(true)
    this.componentsTable.url = this.db_service.getData(this.getSales);

    this.resetTable();
  }
}
