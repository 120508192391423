import { Injectable } from "@angular/core";
import Swal from "sweetalert2";
@Injectable({
  providedIn: "root",
})
export class ElementService {
  constructor() {}

  alertSwal(option: any) {
    return Swal.fire(option);
  }
}
