import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'download-excel',
  templateUrl: './download-excel.component.html',
  styleUrls: ['./download-excel.component.scss']
})
export class DownloadExcelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
