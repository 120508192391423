import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OrderDetail } from "src/app/interfaces/Order-interfaces/OrderDetailEntity";
import { Order } from "src/app/interfaces/Order-interfaces/OrderEntity";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(private router: Router) {}
  idOrder: number = 0;
  
  //datatables content

  orderHtml(element: Order): any[] {
    return [
      "<span style='font-size:11px'>" + element.numberOrder + "</span>",
      element.stateOrder == 1
        ? "<span class='badge badge-success'>cerrado</span>"
        : "<span class='badge badge-warning'>abierto</span>",
      element.codeCustomer,
      element.nameCustomer,
      element.datePlanned,
      "<span style='font-size:11px'>" + element.dateCreated + "</span>",

      Number(element.quantityOrdered).toFixed(0),
      Number(element.quantityPrepared).toFixed(0),
      Number(element.quantityDiferent).toFixed(0),
      "",
    ];
  }

  orderDetailHtml(element: OrderDetail): any[] {
    console.log(element.stateOrder);
    return [
      "<span style='font-size:11px'>" + element.numberOrder + "</span>",
      element.stateOrder == 0
        ? "<span class='badge badge-success'>cerrado</span>"
        : "<span class='badge badge-warning'>abierto</span>",
      element.codeCustomer,
      element.nameCustomer,
      element.datePlanned,
      "<span style='font-size:11px'>" + element.dateCreated + "</span>",
      element.codeItem,
      element.ean,
      element.descriptionItem,
      Number(element.quantityOrdered).toFixed(0),
      Number(element.quantityPrepared).toFixed(0),
      Number(element.quantityDiferent).toFixed(0),
    ];
  }

  orderButtons(response: any): any {
    return [
      {
        select: 9,
        render: function (data, cell, row) {
          return (
            data +
            '<a><i title="detalle Pedido" class="mdi mdi-file-find" style="font-size:200%" detail-order id-order=' +
            response[row.dataIndex].idOrder +
            "></i></a> "
          );
        },
      },
    ];
  }

  //buttons events datatable

  getIdOrder(event: any) {
    event.target.hasAttribute("id-order")
      ? (this.idOrder = event.target.getAttribute("id-order"))
      : "";
  }

  navigateDetailOrder(event: any) {
    event.target.hasAttribute("detail-order")
      ? this.router.navigate([
          "orders/orderDetail" + event.target.getAttribute("detail-order"),
        ])
      : "";
  }
}
