import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { ClientService, DbService, DataTableService, UserService } from "src/app/services/export-services";
import { getElements, tableElements } from "src/app/interfaces/export-interfaces";


@Component({
  selector: "app-data-table",
  templateUrl: "./clients-list.component.html",
  styleUrls: ["./clients-list.component.scss"],
})
export class ClientsListComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public _http: HttpClient,
    public _userService: UserService,
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: ClientService,
    private renderer: Renderer2,
    private router: Router
  ) { }

  subTable: Subscription = new Subscription();
  show$: Observable<boolean> = this.dataTable.showSpinner;
  get: getElements = {
    finalUrl: "/Customers/",
    token: true,
    data1: "",
    data2: "",
  };

  componentsTable: tableElements = {
    url: this.db_service.getData(this.get),
    id: "#dataTable",
    html: this.service.clientHtml,
    actions: this.service.clientButtons,
  };

  ngOnInit(): void {
    this.createTable();
  }

  ngAfterViewInit(): void {
    this.renderer.listen("document", "click", (event) => {
      this.service.getIdClient(event);

      this.service.navigateDetailClient(event);

      this.service.navigateEditClient(event);
    });
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.subTable.unsubscribe();
  }

  goAdd() {
    this.router.navigate(["clients/addClient"]);
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }
}
