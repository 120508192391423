import { AfterViewInit, Component, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ControlHistoryEmployeeComponent } from "../control-history-employee/control-history-employee.component";
import { getElements,tableElements } from "src/app/interfaces/export-interfaces";
import { EmployeeService,DbService, DataTableService } from "src/app/services/export-services";

@Component({
  selector: "app-employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.scss"],
})


export class EmployeesComponent implements OnInit, AfterViewInit {
  constructor(
    private db_service: DbService,
    private dataTable: DataTableService,
    private service: EmployeeService,
    private renderer: Renderer2,
    private router: Router,
    private modalService: NgbModal,
  ) {}

  //addForm!: FormGroup;
  show$:Observable<boolean>= this.dataTable.showSpinner;
  basicModalCloseResult: string = "";
  sub: Subscription;
  subTable: Subscription;
  show: boolean;

  get:getElements = {
    finalUrl: "/getListEmployee/",
    token: true,
    data1: "",
    data2: "",
  };
  componentsTable:tableElements = {
    url: this.db_service.getData(this.get),
    id: "#dataTable",
    html: this.service.employeeHtml,
    actions: this.service.employeeButtons,
    order: "id",
  };

  ngOnInit(): void {
    this.createTable();
    this.sub = this.dataTable.showSpinner.subscribe((res) => (this.show = res));
  }

  ngAfterViewInit(): void {
    /*Nos permite asignar funciones a los botones,etc de los datatables*/
    this.renderer.listen("document", "click", (event) => {
      if (event.target.hasAttribute("delete-employee")) {
        this.delete();
      }
      this.service.getIdEmployee(event);
      this.service.navigateEditEmployee(event);
      this.service.navigateHistoryEmployee(event);
    });
  }

  ngOnDestroy(): void {
    this.dataTable.showSpinner.next(true);
    this.sub.unsubscribe();
  }

  delete() {
   /* this.elementView.alertSwal(this.swalOptions).then((res) => {
      res.isConfirmed == true ? console.log("HOLAA") : "";
    });*/
  }

  goAdd() {
    this.router.navigate(["employee/addEmployee"]);
  }

  createTable() {
    this.subTable = this.componentsTable.url.subscribe((response) => {
      this.dataTable.fillTable(this.componentsTable.html, response, "");

      this.dataTable.selectDatatable(this.componentsTable, response);
    });
  }

  openBasicModal() {
    this.modalService
      .open(ControlHistoryEmployeeComponent, {})
      .result.then((result) => {
        this.basicModalCloseResult = "Modal closed" + result;
      })
      .catch((res) => {});
  }

}
