import { Component, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";

import { DashboardService } from "src/app/services/component-service/dashboard/dashboard.service";

import { ChartOptions } from "src/app/services/component-service/dashboard/dashboard.service";

@Component({
  selector: "app-dashboard-graphic",
  templateUrl: "./dashboard-graphic.component.html",
  styleUrls: ["./dashboard-graphic.component.scss"],
})
export class DashboardGraphicComponent implements OnInit, OnDestroy {
  chartApex: Partial<ChartOptions> = {};
  sub: Subscription;
  constructor(private dashboard: DashboardService) { }

  ngOnInit(): void {
    let currentTime = new Date();
    this.dashboard.year = currentTime.getFullYear();
    this.sub = this.dashboard
      .getSalesComparativexMonth(this.dashboard.year)
      .subscribe((res) => {
        this.chartApex = {
          series: this.dashboard.seriesOptions(res),

          labels: this.dashboard.labelOptions(),

          grid: this.dashboard.gridOptions(),

          chart: this.dashboard.chartOptions(),

          markers: { size: 0 },

          stroke: this.dashboard.strokeOptions(),

          legend: this.dashboard.legendOptions(),

          plotOptions: this.dashboard.plotOpt(),

          fill: this.dashboard.fillOptions(),

          tooltip: this.dashboard.tooltipOptions(),
        };
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
