import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { UserEntity } from "../../../interfaces/UserEntity";
import { UserService } from "../../../services/auth-service/user.service";
import Swal from "sweetalert2";
import { FileService } from "src/app/services/shared/files/files.service";
import { ElementService } from "src/app/services/shared/visible-elements/elements.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [UserService],
})
export class LoginComponent implements OnInit, OnDestroy {
  swalOption = {
    icon: "error",
    title: "Oops...",
    text: "Usuario o contraseña incorrectos",
    footer: '<a href="/auth/reset">¿Has olvidado la contraseña?</a>',
  };
  public user: UserEntity = {
    id: 0,
    name: "",
    company: "",
    email: "",
    gettoken: false,
    idempresa: 0,
  };

  public status: string = "";

  public token: string = "";

  public identity: UserEntity = {
    id: 0,
    name: "",
    company: "",
    email: "",
    gettoken: false,
    idempresa: 0,
  };

  returnUrl: any;

  constructor(
    private _userservice: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: FileService,
    private elementView: ElementService
  ) {}
  ngOnDestroy(): void {
    location.reload();
  }

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/dashboard";
  }

  onSubmit() {
    this._userservice.signup(this.user, true).subscribe(
      (response) => {

        response.status != "error"
          ? ((this.status = "success"),
            (this.token = response.token),
            this.signupWithoutToken())
          : "";
      },
      (error) => {
        this.status = "error";
        this.elementView.alertSwal(this.swalOption);
      }
    );
  }

  signupWithoutToken() {
    this._userservice.signup(this.user, false).subscribe((response) => {
      this.identity = {
        id: response.id,
        name: response.name,
        company: response.company,
        email: response.email,
        gettoken: false,
        idempresa: response.idempresa,
      };
      localStorage.setItem("identity", JSON.stringify(this.identity));
      localStorage.setItem("token", this.token);
      localStorage.setItem("isLoggedin", "true");
      if (localStorage.getItem("isLoggedin")) {
        this.router.navigate([this.returnUrl]);
      }
    });
  }

  onLoggedin(e) {
    e.preventDefault();
  }
}
