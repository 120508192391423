import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinners-load',
  templateUrl: './spinners-load.component.html',
  styleUrls: ['./spinners-load.component.scss']
})
export class SpinnersLoadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
